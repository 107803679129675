var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Grid;
            (function (Grid_1) {
                var Grid = /** @class */ (function () {
                    function Grid(scope, searchFunction, gridOptions, $timeout, uiGridConstants, $window, loadMeasuringSvc, urlBinderFactory, propertyMappers, searchCriteriaSetter) {
                        this.scope = scope;
                        this.searchFunction = searchFunction;
                        this.gridOptions = gridOptions;
                        this.$timeout = $timeout;
                        this.uiGridConstants = uiGridConstants;
                        this.$window = $window;
                        this.loadMeasuringSvc = loadMeasuringSvc;
                        this.urlBinderFactory = urlBinderFactory;
                        this.propertyMappers = propertyMappers;
                        this.searchCriteriaSetter = searchCriteriaSetter;
                        this.searchCriteria = {};
                        this.init();
                        if (this.searchCriteriaSetter) {
                            this.searchCriteriaSetter(this.searchCriteria);
                        }
                    }
                    Grid.prototype.isSearchFiltered = function () {
                        for (var key in this.searchCriteria) {
                            if (key !== 'page' &&
                                key !== 'pageSize' &&
                                key !== 'sortField' &&
                                key !== 'sortOrder' &&
                                this.searchCriteria.hasOwnProperty(key)) {
                                if (_(this.searchCriteria[key]).isDate() ||
                                    ((_(this.searchCriteria[key]).isArray() || _(this.searchCriteria[key]).isObject()) &&
                                        !_(this.searchCriteria[key]).isEmpty()) ||
                                    (!(_(this.searchCriteria[key]).isArray() || _(this.searchCriteria[key]).isObject()) &&
                                        this.searchCriteria[key] != null &&
                                        this.searchCriteria[key] !== '')) {
                                    this.filtered = true;
                                    return true;
                                }
                            }
                        }
                        this.filtered = false;
                        return false;
                    };
                    Grid.prototype.setCriteria = function (criteria) {
                        angular.copy(criteria, this.searchCriteria);
                    };
                    Grid.prototype.clearCriteria = function () {
                        this.clear(this.searchCriteria);
                    };
                    Grid.prototype.search = function () {
                        if (this.urlBinder != null) {
                            this.searchCriteria.page = this.pagingOptions.currentPage;
                            this.searchCriteria.pageSize = this.pagingOptions.pageSize;
                            this.searchCriteria.sortOrder = this.sortOptions.direction;
                            this.searchCriteria.sortField = this.sortOptions.field;
                            this.urlBinder.reflectInUrl(true);
                        }
                        if (!this.searchFunction) {
                            return jQuery.when({ Items: [], Total: 0 });
                        }
                        else {
                            var deferred_1 = jQuery.Deferred();
                            this.executeSearch(false).then(function (result) {
                                deferred_1.resolve(result);
                            }, function (error) {
                                deferred_1.reject(error);
                            });
                            return deferred_1.promise();
                        }
                    };
                    Grid.prototype.currentPageChanged = function () {
                        if (this.urlBinder != null) {
                            this.searchCriteria.page = this.pagingOptions.currentPage;
                            this.urlBinder.reflectInUrl(true);
                        }
                        this.loadMeasuringSvc.stopMeasuring();
                        this.executeSearch(true);
                    };
                    Grid.prototype.setData = function (newData) {
                        var _this = this;
                        newData = newData || [];
                        this.gridOptions.data = newData;
                        this.gridOptions.virtualizationThreshold = newData.length;
                        // hacky fix to force a redraw of the grid
                        this.$timeout(function () {
                            if (_this.gridApi) {
                                _this.gridApi.core.handleWindowResize();
                            }
                        });
                    };
                    Grid.prototype.getData = function () {
                        return this.gridOptions.data;
                    };
                    Grid.prototype.addRow = function (newRow) {
                        var _this = this;
                        this.gridOptions.data.push(newRow);
                        // hacky fix to force a redraw of the grid
                        this.$timeout(function () {
                            if (_this.gridApi) {
                                _this.gridApi.core.handleWindowResize();
                            }
                        });
                        this.gridOptions.virtualizationThreshold++;
                    };
                    Grid.prototype.hasData = function () {
                        return this.gridOptions.data.length != null && this.gridOptions.data.length > 0;
                    };
                    Grid.prototype.showColumn = function (columnName) {
                        var col = this.getColumn(columnName);
                        if (col != null) {
                            col.visible = true;
                            this.notifyColumnsChanged();
                        }
                    };
                    Grid.prototype.hideColumn = function (columnName) {
                        var col = this.getColumn(columnName);
                        if (col != null) {
                            col.visible = false;
                            this.notifyColumnsChanged();
                        }
                    };
                    Grid.prototype.setColumnVisibility = function (columnIndex, visible, notifyChange) {
                        if (notifyChange === void 0) { notifyChange = true; }
                        var col = this.gridOptions.columnDefs[columnIndex];
                        col.visible = visible;
                        if (notifyChange) {
                            this.notifyColumnsChanged();
                        }
                    };
                    Grid.prototype.changeColumnName = function (columnIndex, displayName, notifyChange) {
                        if (notifyChange === void 0) { notifyChange = true; }
                        var col = this.gridOptions.columnDefs[columnIndex];
                        col.displayName = displayName;
                        if (notifyChange) {
                            this.notifyColumnsChanged();
                        }
                    };
                    Grid.prototype.notifyColumnsChanged = function () {
                        if (this.gridApi) {
                            this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
                        }
                    };
                    Grid.prototype.resize = function () {
                        this.gridApi.core.handleWindowResize();
                    };
                    Grid.prototype.hasNonEmptyUrlBinder = function () {
                        return this.urlBinder && !this.urlBinder.isEmpty();
                    };
                    Grid.prototype.init = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.sortOptions = this.generateSortOptions(this.gridOptions);
                                        this.pagingOptions = this.scope.pagingOptions = {
                                            pageSize: 10,
                                            currentPage: 1,
                                        };
                                        this.gridOptions.data = [];
                                        this.gridOptions.enableVerticalScrollbar = this.uiGridConstants.scrollbars.NEVER;
                                        if (!(this.urlBinderFactory != null)) return [3 /*break*/, 2];
                                        this.urlBinder = this.urlBinderFactory.createUrlBinder(this.searchCriteria, this.propertyMappers);
                                        return [4 /*yield*/, this.urlBinder.bindAllFromUrl()];
                                    case 1:
                                        _a.sent();
                                        if (!this.urlBinder.isEmpty()) {
                                            this.pagingOptions.currentPage = this.searchCriteria.page
                                                ? this.searchCriteria.page
                                                : this.pagingOptions.currentPage;
                                            this.pagingOptions.pageSize = this.searchCriteria.pageSize
                                                ? this.searchCriteria.pageSize
                                                : this.pagingOptions.pageSize;
                                            this.sortOptions.direction = this.searchCriteria.sortOrder
                                                ? this.searchCriteria.sortOrder
                                                : this.sortOptions.direction;
                                            this.sortOptions.field = this.searchCriteria.sortField
                                                ? this.searchCriteria.sortField
                                                : this.sortOptions.field;
                                        }
                                        _a.label = 2;
                                    case 2:
                                        if (this.gridOptions.searchWhenReady || this.hasNonEmptyUrlBinder()) {
                                            this.search();
                                        }
                                        if (this.$window.innerWidth < 1100) {
                                            this.gridOptions.columnDefs.forEach(function (cd) {
                                                if (cd.visibleWhenSmall === false) {
                                                    cd.visible = false;
                                                }
                                            });
                                        }
                                        this.scope.$on('panelUncollapsed', function () {
                                            _this.$timeout(function () {
                                                if (_this.gridApi) {
                                                    _this.gridApi.core.handleWindowResize();
                                                }
                                            });
                                        });
                                        this.$window.addEventListener('resize', function () {
                                            if (_this.$window.innerWidth < 1100) {
                                                _this.dynamicallyChangeGridForSmallScreen();
                                            }
                                            else {
                                                _this.dynamicallyChangeGridForBigScreen();
                                            }
                                            _this.refreshGrid();
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    Grid.prototype.dynamicallyChangeGridForSmallScreen = function () {
                        this.gridOptions.columnDefs.forEach(function (cd) {
                            if (cd.visibleWhenSmall === false) {
                                cd.visible = false;
                            }
                        });
                    };
                    Grid.prototype.dynamicallyChangeGridForBigScreen = function () {
                        this.gridOptions.columnDefs.forEach(function (cd) {
                            cd.visible = true;
                        });
                    };
                    Grid.prototype.refreshGrid = function () {
                        if (this.gridApi) {
                            this.gridApi.core.refresh();
                        }
                    };
                    Grid.prototype.generateSortOptions = function (gridOptions) {
                        var columnWithSorting = _.find(gridOptions.columnDefs, function (cd) { return cd.sort && cd.sort.direction; });
                        if (columnWithSorting) {
                            return {
                                field: columnWithSorting.field,
                                direction: columnWithSorting.sort.direction === this.uiGridConstants.ASC ? 'asc' : 'desc',
                            };
                        }
                        else {
                            return {
                                field: null,
                                direction: null,
                            };
                        }
                    };
                    Grid.prototype.clear = function (object) {
                        for (var key in object) {
                            if (object.hasOwnProperty(key)) {
                                if (_.isArray(object[key])) {
                                    object[key] = [];
                                }
                                else if (_.isDate(object[key])) {
                                    object[key] = null;
                                }
                                else if (_.isObject(object[key])) {
                                    this.clear(object[key]);
                                }
                                else if (_.isFunction(object[key])) {
                                    // leave functions in place
                                }
                                else {
                                    object[key] = null;
                                }
                            }
                        }
                    };
                    Grid.prototype.getColumn = function (columnName) {
                        return _.find(this.gridOptions.columnDefs, function (column) { return column.field === columnName || column.name === columnName; });
                    };
                    return Grid;
                }());
                Grid_1.Grid = Grid;
            })(Grid = Framework.Grid || (Framework.Grid = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
