namespace RemeCare.Shared.Framework.Directive {
    class RemeCareGridController implements ng.IComponentController {
        public grid: Framework.Grid.Grid<unknown>;
        private shown = false;
        private checkTimeoutMs = 300;
        private destroyed = false;

        constructor(private readonly $element: ng.IAugmentedJQuery, private readonly $timeout: ng.ITimeoutService) {}

        public $onInit(): void {
            setTimeout(() => this.loopVisibilityCheck(), this.checkTimeoutMs);
        }

        public $onDestroy(): void {
            this.destroyed = true;
        }

        /**
         * Attempt at working around grids not rendering properly, having all columns collapsed
         */
        private loopVisibilityCheck(): void {
            if (this.destroyed) {
                return;
            }

            this.checkVisibility();
            /**
             * setTimeout instead of $timeout to avoid triggering $scope.$apply when there are no changes
             * Using $timeout for doing the visible check (often) would have a big performance impact
             * Impact is minimal now (3 if-checks each 300ms for each grid)
             * Using $timeout in the actual change (when becoming visible) ensures properly propagating the $scope changes
             * https://stackoverflow.com/a/39311081
             */
            setTimeout(() => this.loopVisibilityCheck(), this.checkTimeoutMs);
        }

        private checkVisibility(): void {
            const visible = this.isVisible();
            if (this.shown === visible) {
                return;
            }

            this.shown = visible;
            if (visible) {
                /**
                 * Use $timeout here to have angular properly detect potential $scope changes
                 */
                this.$timeout(() => {
                    if (this.grid && this.grid.gridApi) {
                        this.grid.resize();
                    }
                });
            }
        }

        private isVisible(): boolean {
            return this.$element.width() !== 0 || this.$element.height() !== 0;
        }
    }

    remeCareSharedModule.component('rcGrid', {
        controller: RemeCareGridController,
        bindings: {
            grid: '<', // The grid to display
            parent: '<?', // The parent controller, for when you need to access functions/vars from the grid column config
        },
        template: [
            '$attrs',
            '$templateCache',
            ($attrs: ng.IAttributes, $templateCache: ng.ITemplateCacheService) => {
                let template = $templateCache.get<string>('framework/directives/grid/remecareGrid.html');
                if (!('expandable' in $attrs)) {
                    template = template.replace('ui-grid-expandable', '');
                }
                if (!('autoResize' in $attrs)) {
                    template = template.replace('ui-grid-auto-resize', '');
                }
                return template;
            },
        ],
    });
}
