var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CareRequestPartType;
                (function (CareRequestPartType) {
                    CareRequestPartType[CareRequestPartType["Patient"] = 1] = "Patient";
                    CareRequestPartType[CareRequestPartType["CareRequestor"] = 2] = "CareRequestor";
                    CareRequestPartType[CareRequestPartType["CareRequestRole"] = 3] = "CareRequestRole";
                    CareRequestPartType[CareRequestPartType["QuantitativeAnamnesis"] = 4] = "QuantitativeAnamnesis";
                    CareRequestPartType[CareRequestPartType["QualitativeAnamnesis"] = 5] = "QualitativeAnamnesis";
                    CareRequestPartType[CareRequestPartType["TherapyOption"] = 6] = "TherapyOption";
                    CareRequestPartType[CareRequestPartType["Date"] = 7] = "Date";
                    CareRequestPartType[CareRequestPartType["String"] = 8] = "String";
                    CareRequestPartType[CareRequestPartType["RequestDocument"] = 9] = "RequestDocument";
                    CareRequestPartType[CareRequestPartType["Hospitalisation"] = 10] = "Hospitalisation";
                    CareRequestPartType[CareRequestPartType["Consultation"] = 11] = "Consultation";
                    CareRequestPartType[CareRequestPartType["MedicationSchemaTherapyAction"] = 12] = "MedicationSchemaTherapyAction";
                    CareRequestPartType[CareRequestPartType["MedicationSchemaOther"] = 13] = "MedicationSchemaOther";
                    CareRequestPartType[CareRequestPartType["Comorbidities"] = 14] = "Comorbidities";
                    CareRequestPartType[CareRequestPartType["TreatedDiseases"] = 15] = "TreatedDiseases";
                    CareRequestPartType[CareRequestPartType["Signature"] = 16] = "Signature";
                })(CareRequestPartType = Code.CareRequestPartType || (Code.CareRequestPartType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
