namespace RemeCare.Shared.Framework {
    import Medication = Shared.Contract.Read.IMedication;
    import SearchMedicationQuery = Shared.Contract.Read.Query.ISearchMedicationQuery;

    interface ISearchMedicationCriteria {
        medicationName?: string;
        activeSubstance?: string;
    }

    interface ISearchMedicationScope extends Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        searchMedicationGrid: Framework.Grid.Grid<Medication>;
        selectedMedicationGrid: Framework.Grid.Grid<Medication>;
        medications: Medication[];
        isBusy: boolean;
        criteriaCollapsed: boolean;
        totalItems: number;

        clearSearchParams: () => void;
        toggleCriteria: () => void;
        search: () => void;
        select(): void;
    }

    class SearchProductCtrl extends Framework.ControllerBase<ISearchMedicationScope> {
        constructor(
            protected $scope: ISearchMedicationScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Framework.Toaster,
            private gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            private uiGridConstants,
            private medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) {
            super($scope, $translate, toaster);

            this.$scope.medications = [];

            this.$scope.clearSearchParams = () => this.clearSearchParams();
            this.$scope.toggleCriteria = () => this.toggleCriteria();
            this.$scope.search = () => this.search();

            this.buildGrid();
            this.buildSelectedGrid();

            this.$scope.searchMedicationGrid.sortOptions = {
                field: 'MedicationName',
                direction: uiGridConstants.ASC,
            };
            this.$scope.searchMedicationGrid.searchCriteria = {};

            this.$scope.select = () => this.select();
        }

        public $onInit(): void {
            this.$scope.criteriaCollapsed = false;
        }

        public clearSearchParams(): void {
            this.$scope.searchMedicationGrid.searchCriteria = {};
        }

        public toggleCriteria(): void {
            this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
        }

        public search(): void {
            this.$scope.searchMedicationGrid.pagingOptions.currentPage = 1;
            this.$scope.searchMedicationGrid.search();
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: ISearchMedicationCriteria
        ): Promise<Shared.Contract.ISearchResult<Medication>> {
            this.$scope.isBusy = true;
            const query: SearchMedicationQuery = {
                page: page,
                pageSize: pageSize,
                medicationName: criteria.medicationName,
                activeSubstance: criteria.activeSubstance,
                productClassIds: [],
                sortField: sortField,
                sortOrder: sortDirection,
                isActive: true,
            };

            try {
                const data = await this.medicationSearchSvc.searchMedicationsAsync(query);
                this.$scope.totalItems = data.Total;
                this.$scope.isBusy = false;
                this.$scope.criteriaCollapsed = true;
                return data;
            } catch (error) {
                this.errorCallback(error);
            }
        }

        private buildGrid(): void {
            this.$scope.searchMedicationGrid = this.gridBuilderSvc
                .createGridBuilder<Medication>(
                    (
                        page: number,
                        pageSize: number,
                        sortField: string,
                        sortDirection: string,
                        criteria: ISearchMedicationCriteria
                    ) => this.executeSearch(page, pageSize, sortField, sortDirection, criteria)
                )
                .addColumn('MedicationName', 'General.MedicationName')
                .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                .addColumn('GalenicForm', 'General.GalenicForm')
                .addColumn('MedicationTypes', 'General.MedicationType', {
                    cellFilter: 'delimitedDisplay',
                    enableSorting: false,
                })
                .addSelectButtonColumn((selected) => this.resultSelected(selected))
                .build();
        }

        private buildSelectedGrid(): void {
            this.$scope.selectedMedicationGrid = this.gridBuilderSvc
                .createGridBuilder<Medication>()
                .addColumn('MedicationName', 'General.MedicationName')
                .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                .addColumn('GalenicForm', 'General.GalenicForm')
                .addColumn('MedicationTypes', 'General.MedicationType', {
                    cellFilter: 'delimitedDisplay',
                    enableSorting: false,
                })
                .addDeleteButtonColumn((selected) => this.resultRemoved(selected))
                .setExternalSorting(false)
                .build();
            this.$scope.selectedMedicationGrid.setData(this.$scope.medications);
        }

        private resultSelected(result: Medication): void {
            if (!_(this.$scope.medications).any((m) => m.ProductId === result.ProductId)) {
                this.$scope.medications.push(result);
            }
        }

        private resultRemoved(result: Medication): void {
            const index = this.$scope.medications.indexOf(result);
            this.$scope.medications.splice(index, 1);
        }

        private select(): void {
            this.$scope.$close(this.$scope.medications);
        }
    }

    remeCareSharedModule.controller(
        'searchProductCtrl',
        (
            $scope: ISearchMedicationScope,
            $translate,
            toaster: Framework.Toaster,
            gridBuilderSvc: Framework.Grid.GridBuilderFactory,
            uiGridConstants,
            medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) => new SearchProductCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, medicationSearchSvc)
    );
}
