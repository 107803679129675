namespace RemeCare.Shared.Framework {
    import IntraDayTiming = Framework.Model.IntraDayTiming;

    interface IExtendedPeriodicity {
        periodicity: Framework.Model.Periodicity;
        grid: Shared.Framework.Grid.Grid<IntraDayTiming>;
    }

    class ShowReadOnlyPeriodicityModalController implements ng.IComponentController {
        public recurrentCycle: Framework.Model.RecurrentCycle;
        public intraDayTimingsGrid: Framework.Grid.Grid<IntraDayTiming>;
        public periodicities: IExtendedPeriodicity[];
        public carePlanStartDate: Date;
        public CarePlanStartDateReadable: string;
        public days: Shared.Service.IDay[];

        constructor(
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly $locale: ng.ILocaleService,
            private readonly daySvc: Shared.Service.DayService
        ) {}

        public async $onInit(): Promise<void> {
            this.initialisePeriodicities();
            this.CarePlanStartDateReadable = DateHelper.dateHumanReadible(this.carePlanStartDate);
            this.days = this.daySvc.getDays();
        }

        public isDayChecked(dayValue: number, daysOfWeekValue: number): boolean {
            return (daysOfWeekValue & dayValue) === dayValue;
        }

        public getDaysChecked(daysOfWeekValue: number): string {
            let daysString = '';
            _.forEach(this.days, d => {
                if (this.isDayChecked(d.value, daysOfWeekValue)) {
                    if (daysString !== '') {
                        daysString = daysString + ', ';
                    }
                    daysString = daysString + ' ' + d.name;
                }
            });
            return daysString;
        }

        public getWeekDayOccurance(value: number): string {
            const weekDayOccurences = this.daySvc.getWeekDayOccurences();
            const occurence = _.find(weekDayOccurences, wdo => wdo.value === value);
            if (occurence != null) {
                return occurence.text;
            } else {
                return '';
            }
        }

        public getMonth(value: number): string {
            const months = _(this.$locale.DATETIME_FORMATS.MONTH).map((m, i) => {
                return {
                    value: i + 1,
                    name: m,
                };
            });
            const month = _.find(months, m => m.value === value);
            if (month != null) {
                return month.name;
            } else {
                return '';
            }
        }

        private initialisePeriodicities(): void {
            this.periodicities = [];
            for (const periodicity of this.recurrentCycle.periodicities) {
                const grid = this.buildGrid();
                grid.setData(periodicity.intraDayTimings);
                this.periodicities.push({
                    grid,
                    periodicity,
                } as IExtendedPeriodicity);
            }
        }

        private buildGrid(): Shared.Framework.Grid.Grid<IntraDayTiming> {
            return (
                this.gridBuilderSvc
                    .createGridBuilder<IntraDayTiming>()
                    // No addDateColumn on purpose, because it sets a fixed width
                    .addColumn('preferredTime', 'Periodicity.PreferredTime', {
                        cellFilter: 'date: "HH:mm"',
                        sort: { direction: 'asc' },
                    })
                    .addColumn('preferredTimeWindowBefore', 'Periodicity.PreferredTimeWindowBefore', {
                        cellFilter: 'timeSpanDisplayFilter',
                    })
                    .addColumn('preferredTimeWindowAfter', 'Periodicity.PreferredTimeWindowAfter', {
                        cellFilter: 'timeSpanDisplayFilter',
                    })
                    .addColumn(
                        'reminderElapseTimeAfterOpeningWindow',
                        'Periodicity.ReminderElapseTimeAfterOpeningWindow',
                        {
                            cellFilter: 'timeSpanDisplayFilter',
                        }
                    )
                    .build()
            );
        }
    }

    remeCareSharedModule.component('rcShowReadOnlyPeriodicity', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            recurrentCycle: '<',
            carePlanStartDate: '<',
        },
        controller: ShowReadOnlyPeriodicityModalController,
        templateUrl: 'framework/components/showReadOnlyPeriodicity/showReadOnlyPeriodicity.html',
    });
}
