var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var ReferenceDataTypes = /** @class */ (function () {
                function ReferenceDataTypes() {
                }
                ReferenceDataTypes.actionAssignmentType = 'ActionAssignmentType';
                ReferenceDataTypes.actionType = 'ActionType';
                ReferenceDataTypes.guidanceStepType = 'GuidanceStepType';
                ReferenceDataTypes.bleParameter = 'BLEParameter';
                ReferenceDataTypes.bleService = 'BLEService';
                ReferenceDataTypes.carePlanActionStatus = 'CarePlanActionStatus';
                ReferenceDataTypes.contactPersonType = 'ContactPersonType';
                ReferenceDataTypes.contactPointType = 'ContactPointType';
                ReferenceDataTypes.contactPointUsageType = 'ContactPointUsageType';
                ReferenceDataTypes.informationObjectType = 'InformationObjectType';
                ReferenceDataTypes.patientFileDocumentSource = 'PatientFileDocumentSource';
                ReferenceDataTypes.administrationMethod = 'AdministrationMethod';
                ReferenceDataTypes.solventToAddUnit = 'SolventToAddUnit';
                ReferenceDataTypes.durationUnit = 'DurationUnit';
                ReferenceDataTypes.speedUnit = 'SpeedUnit';
                ReferenceDataTypes.prescribedAdministrationMeans = 'PrescribedAdministrationMeans';
                ReferenceDataTypes.partyRoleTypes = 'PartyRoleTypes';
                ReferenceDataTypes.healthCareProfessionalTypes = 'HealthCareProfessionalTypes';
                ReferenceDataTypes.partWidth = 'PartWidth';
                ReferenceDataTypes.country = 'Country';
                ReferenceDataTypes.requestAccessTypes = 'RequestAccessType';
                ReferenceDataTypes.PatientInformedConsentHistoryType = 'PatientInformedConsentHistoryType';
                ReferenceDataTypes.planningDateTimeType = 'PlanningDateTimeType';
                ReferenceDataTypes.communicationTypePreference = 'CommunicationTypePreference';
                ReferenceDataTypes.inputDevice = 'InputDevice';
                ReferenceDataTypes.inputDeviceCharacteristic = 'InputDeviceCharacteristic';
                return ReferenceDataTypes;
            }());
            Framework.ReferenceDataTypes = ReferenceDataTypes;
            var MasterdataService = /** @class */ (function (_super) {
                __extends(MasterdataService, _super);
                function MasterdataService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                MasterdataService.prototype.getReferenceDataAsync = function (enumType) {
                    switch (enumType) {
                        case ReferenceDataTypes.contactPointType:
                            return this.getContactPointTypesAsync();
                        case ReferenceDataTypes.contactPointUsageType:
                            return this.getContactPointUsageTypesAsync();
                        case ReferenceDataTypes.administrationMethod:
                            return this.getAdministrationMethodsAsync();
                        case ReferenceDataTypes.solventToAddUnit:
                            return this.getSolventToAddUnitsAsync();
                        case ReferenceDataTypes.durationUnit:
                            return this.getDurationUnitsAsync();
                        case ReferenceDataTypes.speedUnit:
                            return this.getSpeedUnitsAsync();
                        case ReferenceDataTypes.prescribedAdministrationMeans:
                            return this.getPrescribedAdministrationMeansAsync();
                        case ReferenceDataTypes.partyRoleTypes:
                            return this.getPartyRoleTypesAsync();
                        case ReferenceDataTypes.healthCareProfessionalTypes:
                            return this.getHealthCareProfessionalTypesAsync();
                        case ReferenceDataTypes.country:
                            return this.getCountriesAsync();
                        default:
                            return this.getPromise("ReferenceData/" + enumType, null, 'referenceData', true);
                    }
                };
                /** @deprecated */
                MasterdataService.prototype.getCodeSetItems = function (codeSetType, pointInTime) {
                    var url = "ChoiceLists/" + codeSetType + "/ChoiceListOptions";
                    if (pointInTime) {
                        url += "/" + pointInTime;
                    }
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getCodeSetItemsAsync = function (codeSetType, pointInTime) {
                    var url = "ChoiceLists/" + codeSetType + "/ChoiceListOptions";
                    if (pointInTime) {
                        url += "/" + pointInTime;
                    }
                    return this.getPromise(url, null, null, true);
                };
                MasterdataService.prototype.getCommLanguagesAsync = function () {
                    return this.getPromise('Languages/Comm', null, 'languages.comm', true);
                };
                /** @deprecated */
                MasterdataService.prototype.getCommLanguages = function () {
                    return this.get('Languages/Comm', null, 'languages.comm', true);
                };
                /** @deprecated */
                MasterdataService.prototype.getContactPersonTypes = function () {
                    return this.get('ContactPersonType', null, null, true);
                };
                MasterdataService.prototype.getContactPersonTypesAsync = function () {
                    return this.getPromise('ContactPersonType', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getContactPointTypes = function () {
                    return this.get('ContactPointTypes', null, null, true);
                };
                MasterdataService.prototype.getContactPointTypesAsync = function () {
                    return this.getPromise('ContactPointTypes', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getContactPointUsageTypes = function (partyType, contactPointType) {
                    var url = !partyType && !contactPointType
                        ? 'ContactPointUsageTypes'
                        : "ContactPointUsageType/" + partyType + "/" + contactPointType;
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getContactPointUsageTypesAsync = function (partyType, contactPointType) {
                    var url = !partyType && !contactPointType
                        ? 'ContactPointUsageTypes'
                        : "ContactPointUsageType/" + partyType + "/" + contactPointType;
                    return this.getPromise(url, null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getCountries = function () {
                    var url = 'Country';
                    return this.get(url, null, 'countries', true);
                };
                MasterdataService.prototype.getCountriesAsync = function () {
                    var url = 'Country';
                    return this.getPromise(url, null, 'countries', true);
                };
                MasterdataService.prototype.getActionCategoriesAsync = function () {
                    var url = 'ActionCategories';
                    return this.getPromise(url, null, 'actionCategories', true);
                };
                MasterdataService.prototype.getActionCategoriesForReportingAsync = function (includeInactive) {
                    var url = 'ActionCategories?IncludeForReporting=true';
                    if (!includeInactive) {
                        url += '&IsActive=true';
                    }
                    return this.getPromise(url, null, 'actionCategories', true);
                };
                /** @deprecated */
                MasterdataService.prototype.getGenders = function () {
                    var url = 'Gender';
                    return this.get(url, null, 'genders', true);
                };
                MasterdataService.prototype.getGendersAsync = function () {
                    var url = 'Gender';
                    return this.getPromise(url, null, 'genders', true);
                };
                MasterdataService.prototype.getHealthInsuranceFunds = function () {
                    var url = 'HealthInsuranceFund';
                    return this.get(url, null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getLanguages = function () {
                    var url = 'Languages';
                    return this.get(url, null, 'languages', true);
                };
                MasterdataService.prototype.getLanguagesAsync = function () {
                    var url = 'Languages';
                    return this.getPromise(url, null, 'languages', true);
                };
                MasterdataService.prototype.getInterfaceLanguagesAsync = function () {
                    return this.getPromise('Languages/Interface', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getInterfaceLanguages = function () {
                    return this.get('Languages/Interface', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getNationalities = function () {
                    var url = 'Nationality';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getNationalitiesAsync = function () {
                    var url = 'Nationality';
                    return this.getPromise(url, null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getTitles = function () {
                    var url = 'Title';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getTitlesAsync = function () {
                    var url = 'Title';
                    return this.getPromise(url, null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getEducations = function () {
                    var url = 'Education';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getEducationsAsync = function () {
                    var url = 'Education';
                    return this.getPromise(url, null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getMaritalStatusses = function () {
                    var url = 'MaritalStatus';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getMaritalStatussesAsync = function () {
                    var url = 'MaritalStatus';
                    return this.getPromise(url, null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getTechnologicalAffinities = function () {
                    var url = 'TechnologicalAffinity';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getTechnologicalAffinitiesAsync = function () {
                    var url = 'TechnologicalAffinity';
                    return this.getPromise(url, null, null, true);
                };
                MasterdataService.prototype.getCareTypes = function () {
                    var url = 'CareTypes';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getSimplifiedRecurrences = function () {
                    var url = 'SimplifiedRecurrences';
                    return this.get(url, null, null, true);
                };
                MasterdataService.prototype.getConsultationCareProviderTypesAsync = function () {
                    var url = 'ConsultationCareProviderTypes';
                    return this.getPromise(url, null, null, true);
                };
                MasterdataService.prototype.getUnitsAsync = function (includeInactive) {
                    var url = 'Units';
                    var query = includeInactive == null ? null : { includeInactive: includeInactive };
                    return this.getPromise(url, query, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getObservableEntities = function (ids) {
                    var query = ids ? { ids: ids, pageSize: ids.length } : null;
                    return this.get('ObservableEntities', query, null, false, function (r) { return r.Items; });
                };
                MasterdataService.prototype.getObservableEntitiesAsync = function (ids) {
                    if (!ids || !ids.length) {
                        return this.getPromise('ObservableEntities', null, null, false, function (r) { return r.Items; });
                    }
                    return this.getInParts('ObservableEntities', ids, 50, function (set) { return ({ ids: set, pageSize: 50 }); }, null, false, function (r) { return r.Items; });
                };
                MasterdataService.prototype.getCodeSetTypesAsync = function () {
                    return this.getPromise('CodeSetTypes', null, null, false, function (result) { return result.Items; });
                };
                MasterdataService.prototype.findCodeSetTypes = function (ids) {
                    var query = {
                        codeSetTypeIds: ids,
                        pageSize: ids.length,
                        needsConfigurationInfo: false,
                    };
                    return this.getInParts('MasterDataConfiguration/CodeSetTypes', ids, 50, function (set) { return ({ codeSetTypeIds: set, pageSize: 50, needsConfigurationInfo: false }); }, null, false, function (result) { return result.Items; });
                };
                MasterdataService.prototype.getCodeSetTypeUsage = function (codeSetTypeId, careRequestPartLocalId) {
                    var query = {
                        codeSetTypeId: codeSetTypeId,
                        CareRequestPartLocalId: careRequestPartLocalId,
                    };
                    return this.getPromise('MasterDataConfiguration/CodeSetTypeUsage', query);
                };
                /** @deprecated */
                MasterdataService.prototype.getCharacteristicUnits = function () {
                    return this.get('CharacteristicUnits', null, null, false);
                };
                MasterdataService.prototype.getCharacteristicUnitsAsync = function () {
                    return this.getPromise('CharacteristicUnits', null, null, false);
                };
                MasterdataService.prototype.getCharacteristicsAsync = function (ids, showInactive) {
                    if (showInactive === void 0) { showInactive = false; }
                    var query = ids ? { ids: ids, pageSize: ids.length, showInactive: showInactive } : null;
                    return this.getPromise('Characteristics', query, null, null, function (r) { return r.Items; });
                };
                MasterdataService.prototype.getUIControlTypesAsync = function () {
                    return this.getPromise('uiControlTypes', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getTherapyActionStatuses = function () {
                    return this.get('TherapyActionStatuses');
                };
                MasterdataService.prototype.getTherapyActionStatusesAsync = function () {
                    return this.getPromise('TherapyActionStatuses');
                };
                MasterdataService.prototype.getGuidanceStatusesAsync = function () {
                    return this.getPromise('GuidanceStatuses');
                };
                /** @deprecated */
                MasterdataService.prototype.getDurationUnits = function (includeSmallUnits) {
                    return this.get('DurationUnits', { includeSmallUnits: includeSmallUnits }, null, true);
                };
                MasterdataService.prototype.getDurationUnitsAsync = function (includeSmallUnits) {
                    return this.getPromise('DurationUnits', { includeSmallUnits: includeSmallUnits }, null, true);
                };
                MasterdataService.prototype.getDiseaseTypesAsync = function () {
                    return this.getPromise('DiseaseTypes', null, null, true);
                };
                MasterdataService.prototype.getCarePlanStatusesAsync = function () {
                    return this.getPromise('CarePlanStatuses', null, 'carePlanStatuses', true);
                };
                MasterdataService.prototype.getTherapyActionStartTypes = function () {
                    return this.get('TherapyActionStartTypes', null, null, true);
                };
                MasterdataService.prototype.getIntendedValues = function () {
                    return this.getPromise('IntendedValues', null, null, true);
                };
                MasterdataService.prototype.getDateTypes = function () {
                    return this.getPromise('DateTypes', null, null, true);
                };
                MasterdataService.prototype.getInformationObjectTypesAsync = function () {
                    return this.getPromise('InformationObjectTypes', null, null, true);
                };
                MasterdataService.prototype.getAdministrationTimings = function () {
                    return this.getPromise('AdministrationTimings', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getPartyRoleTypes = function (partyType) {
                    if (partyType != null) {
                        return this.get('PartyRoleType/' + partyType, null, 'partyRoleTypes', true);
                    }
                    else {
                        return this.get('PartyRoleType', null, 'partyRoleTypes', true);
                    }
                };
                MasterdataService.prototype.getPartyRoleTypesAsync = function (partyType) {
                    if (partyType != null) {
                        return this.getPromise('PartyRoleType/' + partyType, null, 'partyRoleTypes', true);
                    }
                    else {
                        return this.getPromise('PartyRoleType', null, 'partyRoleTypes', true);
                    }
                };
                MasterdataService.prototype.getHealthCareProfessionalTypesAsync = function () {
                    return this.getPromise('HealthCareProfessionalTypes', null, null, true);
                };
                MasterdataService.prototype.getFileTypes = function (activeOnly) {
                    var query = { activeOnly: activeOnly };
                    return this.getPromise('FileTypes', query, null, true);
                };
                MasterdataService.prototype.getTherapyActionRelationTypesAsync = function () {
                    return this.getPromise('TherapyActionRelationTypes', null, null, true);
                };
                MasterdataService.prototype.getCareRequestStepTypesAsync = function () {
                    return this.getPromise('CareRequestStepTypes', null, null, true);
                };
                MasterdataService.prototype.getCareRequestPartTypes = function () {
                    return this.getPromise('CareRequestPartTypes', null, null, true);
                };
                MasterdataService.prototype.getCareRequestUIControlTypes = function () {
                    return this.get('CareRequestUIControlTypes', null, null, true);
                };
                MasterdataService.prototype.getModalityTypes = function () {
                    return this.get('ModalityTypes', null, null, true);
                };
                MasterdataService.prototype.getAlarmTypesAsync = function () {
                    return this.getPromise('AlarmTypes', null, null, true);
                };
                MasterdataService.prototype.getAlarmLevelsAsync = function () {
                    return this.getPromise('AlarmLevels', null, null, true);
                };
                MasterdataService.prototype.getAlarmStatusesAsync = function () {
                    return this.getPromise('AlarmStatuses', null, null, true);
                };
                MasterdataService.prototype.getTherapyStatuses = function () {
                    return this.get('TherapyStatuses', null, null, true);
                };
                MasterdataService.prototype.getInformationObjectStatuses = function () {
                    return this.getPromise('InformationObjectStatuses', null, null, true);
                };
                MasterdataService.prototype.getContactAttributes = function (partyType) {
                    var url = "ContactAttributes/" + partyType;
                    return this.getPromise(url, null, 'contactAttributes', true);
                };
                MasterdataService.prototype.getDocumentCategoriesAsync = function () {
                    return this.getPromise('DocumentCategories', null, null, true);
                };
                MasterdataService.prototype.getObjectiveTypesAsync = function () {
                    return this.getPromise('ObjectiveTypes', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getLineTypes = function () {
                    return this.get('LineTypes', null, null, true);
                };
                MasterdataService.prototype.getLineTypesAsync = function () {
                    return this.getPromise('LineTypes', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getMonitoringPartDataSourceTypes = function () {
                    return this.get('MonitoringPartDataSourceTypes', null, null, true);
                };
                MasterdataService.prototype.getMonitoringPartDataSourceTypesAsync = function () {
                    return this.getPromise('MonitoringPartDataSourceTypes', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getMonitoringPartTypes = function () {
                    return this.get('MonitoringPartTypes', null, null, true);
                };
                MasterdataService.prototype.getMonitoringPartTypesAsync = function () {
                    return this.getPromise('MonitoringPartTypes', null, null, true);
                };
                /** @deprecated */
                MasterdataService.prototype.getPartWidths = function () {
                    return this.get('PartWidths', null, null, true);
                };
                MasterdataService.prototype.getPartWidthsAsync = function () {
                    return this.getPromise('PartWidths', null, null, true);
                };
                MasterdataService.prototype.getCommunicationTypePreferenceAsync = function () {
                    return this.getPromise('CommunicationTypePreference', null, null, true);
                };
                MasterdataService.prototype.getCarePlanActionTypes = function () {
                    return this.getPromise('CarePlanActionTypes', null, null, true);
                };
                MasterdataService.prototype.getGuidanceStepTypes = function () {
                    return this.getPromise('GuidanceStepTypes', null, null, true);
                };
                MasterdataService.prototype.getRuleThresholdsAsync = function (therapyId, ids) {
                    var url = "TherapyConfiguration/" + therapyId + "/RuleThresholds";
                    return this.getPromise(url, { ids: ids });
                };
                MasterdataService.prototype.getObjectivesAsync = function (therapyId, ids, objectiveTypes) {
                    var url = "TherapyConfiguration/" + therapyId + "/Objectives";
                    return this.getPromise(url, { ids: ids, objectiveTypes: objectiveTypes });
                };
                MasterdataService.prototype.getDateFilterTypesAsync = function () {
                    return this.getPromise('DateFilterTypes', null, null, true);
                };
                MasterdataService.prototype.getCarePlanActionStatuses = function () {
                    return this.getPromise('CarePlanActionStatus', null, null, true);
                };
                MasterdataService.prototype.getApplicationProfilesAsync = function () {
                    return this.getPromise('Security/ApplicationProfiles', null, 'security.applicationProfiles', true);
                };
                MasterdataService.prototype.getStructuralTherapyActionAttributes = function () {
                    return this.getPromise('StructuralTherapyActionAttributes', null, null, true);
                };
                MasterdataService.prototype.getTherapyInformationAttributes = function () {
                    return this.getPromise('TherapyInformationAttributes', null, null, true);
                };
                MasterdataService.prototype.findIngredientsAsync = function (ingredientIds) {
                    return this.getPromise('Ingredients', {
                        IngredientIds: ingredientIds,
                        PageSize: ingredientIds.length,
                    });
                };
                MasterdataService.prototype.findProductUnitsAsync = function (unitIds) {
                    return this.getPromise('ProductUnits', { UnitIds: unitIds });
                };
                MasterdataService.prototype.findMedicationTypes = function () {
                    return this.getPromise('MedicationTypes', null, null, true);
                };
                MasterdataService.prototype.findCareRequestFilterTypesAsync = function () {
                    return this.getPromise('CareRequestFilterTypes', null, 'careRequestFilterTypes', true);
                };
                MasterdataService.prototype.getTranslation = function (culture, key) {
                    return this.get("Localization/" + culture + "/" + key, null, null, true);
                };
                MasterdataService.prototype.getSolventToAddUnitsAsync = function () {
                    var url = 'SolventToAddUnit';
                    return this.getPromise(url, null, 'solventToAddUnits', true);
                };
                MasterdataService.prototype.getAdministrationMethodsAsync = function () {
                    var url = 'AdministrationMethod';
                    return this.getPromise(url, null, 'administrationMethods', true);
                };
                MasterdataService.prototype.getSpeedUnitsAsync = function () {
                    var url = 'SpeedUnit';
                    return this.getPromise(url, null, 'speedUnits', true);
                };
                MasterdataService.prototype.getPrescribedAdministrationMeansAsync = function () {
                    var url = 'PrescribedAdministrationMeans';
                    return this.getPromise(url, null, 'PrescribedAdministrationMeans', true);
                };
                /** @deprecated */
                MasterdataService.prototype.getMonitoringPartGroups = function () {
                    var _this = this;
                    var url = 'MonitoringPartGroups';
                    return this.get(url, null, 'monitoringPartGroups', true)
                        .then(function (httpResult) {
                        return httpResult.data;
                    })
                        .catch(function (httpError) {
                        return _this.$q.reject(httpError.data);
                    });
                };
                MasterdataService.prototype.getMonitoringPartGroupsAsync = function () {
                    var url = 'MonitoringPartGroups';
                    return this.getPromise(url, null, 'monitoringPartGroups', true);
                };
                MasterdataService.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                return MasterdataService;
            }(Framework.ApiServiceBase));
            Framework.MasterdataService = MasterdataService;
            Shared.remeCareSharedModule.service('masterdataSvc', MasterdataService);
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
