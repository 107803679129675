namespace RemeCare.Shared.Framework.Grid {
    export class PrevNextPagedGrid<T> extends Grid<T> {
        public hasNextPage: boolean;
        public hasPreviousPage: boolean;
        public maxPageReached: number;

        constructor(
            scope: IGridScope,
            searchFunction: PromiseSearchFunction<T>,
            gridOptions: IGridOptions<T>,
            $timeout: ng.ITimeoutService,
            uiGridConstants,
            $window,
            loadMeasuringSvc: Service.LoadMeasuringService,
            urlBinderFactory?: Factory.UrlBinderFactory,
            propertyMappers?: Factory.PropertyMappers,
            searchCriteriaSetter?: (c) => void
        ) {
            super(
                scope,
                searchFunction,
                gridOptions,
                $timeout,
                uiGridConstants,
                $window,
                loadMeasuringSvc,
                urlBinderFactory,
                propertyMappers,
                searchCriteriaSetter
            );
            this.pagingType = PagingType.PrevNext;
            this.maxPageReached = 1;
        }

        public async currentPageChanged(): Promise<void> {
            this.hasPreviousPage = this.pagingOptions.currentPage > 1;
            this.hasNextPage = this.pagingOptions.currentPage < this.maxPageReached;
            this.maxPageReached = Math.max(this.pagingOptions.currentPage, this.maxPageReached);
            await this.executeSearch(true);
        }

        public isPaged(): boolean {
            return this.hasNextPage || this.hasPreviousPage;
        }

        protected async executeSearch(isPagingSearch: boolean): Promise<Shared.Contract.ISearchResult<T>> {
            if (!isPagingSearch) {
                this.maxPageReached = 1;
                this.hasNextPage = false;
                this.totalItems = null;
            }
            const searchResult = await this.searchFunction(
                this.pagingOptions.currentPage,
                this.pagingOptions.pageSize,
                this.sortOptions.field,
                this.sortOptions.direction,
                this.searchCriteria,
                false,
                true
            );
            this.setData(searchResult.Items);
            this.hasNextPage = searchResult.HasNextPage;
            this.maxPageReached = Math.max(this.maxPageReached, this.pagingOptions.currentPage);
            this.isSearchFiltered();
            if (!isPagingSearch) {
                this.searchFunction(1, 0, undefined, undefined, this.searchCriteria, true, false).then(
                    (searchResult) => {
                        this.totalItems = searchResult.Total;
                    }
                );
            }
            return searchResult;
        }
    }
}
