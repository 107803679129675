var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var CodeSystemCode = /** @class */ (function () {
                    function CodeSystemCode(serverObject) {
                        if (serverObject != null) {
                            this.codeSystem = new Model.CodeSystem(serverObject.CodeSystem);
                            this.code = serverObject.Code;
                        }
                    }
                    CodeSystemCode.prototype.toServerWrite = function () {
                        return {
                            code: this.code,
                            codeSystemId: this.codeSystem.id,
                        };
                    };
                    CodeSystemCode.prototype.copy = function () {
                        var result = new CodeSystemCode();
                        result.code = this.code;
                        result.codeSystem = this.codeSystem;
                        return result;
                    };
                    return CodeSystemCode;
                }());
                Model.CodeSystemCode = CodeSystemCode;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
