var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            var timeSpanDisplayFilter = function () {
                return function (timeSpan) {
                    var timeParts = [];
                    var defaultValue = '-';
                    if (!timeSpan) {
                        return defaultValue;
                    }
                    if (timeSpan.Days > 0) {
                        timeParts.push(timeSpan.Days + "d");
                    }
                    if (timeSpan.Hours > 0) {
                        timeParts.push(timeSpan.Hours + "h");
                    }
                    if (timeSpan.Minutes > 0) {
                        timeParts.push(timeSpan.Minutes + "min");
                    }
                    if (timeSpan.Seconds > 0) {
                        timeParts.push(timeSpan.Seconds + "sec");
                    }
                    if (timeParts.length > 0) {
                        return timeParts.join(' ');
                    }
                    else {
                        return defaultValue;
                    }
                };
            };
            angular.module('RemeCare.Shared').filter('timeSpanDisplayFilter', timeSpanDisplayFilter);
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
