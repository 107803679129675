var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var DateHelper = /** @class */ (function () {
            function DateHelper() {
            }
            DateHelper.now = function () {
                var date = new Date();
                return date;
            };
            DateHelper.today = function () {
                var date = new Date();
                date.setHours(0, 0, 0, 0);
                return date;
            };
            DateHelper.tomorrow = function () {
                var date = this.today();
                date.setDate(date.getDate() + 1);
                return date;
            };
            DateHelper.startOfTime = function () {
                return moment('0001-01-01', 'YYYY-MM-DD').toDate();
            };
            DateHelper.endOfTime = function () {
                return moment('9999-12-31', 'YYYY-MM-DD').toDate();
            };
            DateHelper.dayOfDate = function (date) {
                if (date == null) {
                    return null;
                }
                var momentDate = moment(date);
                var result = moment({
                    year: momentDate.year(),
                    month: momentDate.month(),
                    day: momentDate.date(),
                });
                return result.toDate();
            };
            DateHelper.toServerDateTimeString = function (date) {
                if (date == null) {
                    return null;
                }
                var momentDate = moment(date);
                return momentDate.isValid() ? momentDate.utc().format('YYYY-MM-DDTHH:mm:ss') : null;
            };
            DateHelper.momentToServerDateTimeString = function (dateTime) {
                if (dateTime == null) {
                    return null;
                }
                return dateTime.isValid() ? dateTime.utc().toISOString() : null;
            };
            DateHelper.toServerDateString = function (date) {
                if (date == null) {
                    return null;
                }
                var momentDate = moment(date);
                return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') + 'Z' : null;
            };
            DateHelper.toServerTimeString = function (date) {
                if (date == null) {
                    return null;
                }
                var momentDate = moment(date);
                return momentDate.isValid() ? momentDate.format('HH:mm:ss') : null;
            };
            DateHelper.timeSpanToServerTimeString = function (timeSpan) {
                if (timeSpan == null ||
                    (timeSpan.Days == null && timeSpan.Hours == null && timeSpan.Minutes == null && timeSpan.Seconds == null) ||
                    (timeSpan.Days == 0 && timeSpan.Hours == 0 && timeSpan.Minutes == 0 && timeSpan.Seconds == 0)) {
                    return null;
                }
                var d = timeSpan.Days || 0;
                var h = timeSpan.Hours || 0;
                var m = timeSpan.Minutes || 0;
                var s = timeSpan.Seconds || 0;
                return d + "." + this.pad(h, 2) + ":" + this.pad(m, 2) + ":" + this.pad(s, 2);
            };
            DateHelper.pad = function (num, size) {
                var s = num + "";
                while (s.length < size)
                    s = "0" + s;
                return s;
            };
            DateHelper.serverTimeToDate = function (time) {
                if (time == null) {
                    return null;
                }
                var duration = moment.duration(time);
                if (duration.hours() < 0) {
                    duration = moment.duration({
                        seconds: duration.seconds(),
                        minutes: duration.minutes(),
                        hours: duration.hours() + 24,
                        days: -1,
                    });
                }
                var momentDate = moment({
                    hour: Math.abs(duration.hours()),
                    minute: duration.minutes(),
                    second: duration.seconds(),
                });
                momentDate.add(duration.days(), 'days');
                return momentDate.isValid() ? momentDate.toDate() : null;
            };
            DateHelper.serverTimeToTimeSpan = function (time) {
                if (time == null) {
                    return null;
                }
                var timeSpan = new Shared.Framework.Model.TimeSpan(time);
                return timeSpan;
            };
            DateHelper.serverDateStringToDate = function (date) {
                if (date == null) {
                    return null;
                }
                var momentDate = moment(date, 'YYYY-MM-DD');
                return momentDate.isValid() ? momentDate.toDate() : null;
            };
            DateHelper.serverDateStringToDateTime = function (date) {
                if (date == null) {
                    return null;
                }
                var momentDate = moment.utc(date).local();
                return momentDate.isValid() ? momentDate.toDate() : null;
            };
            DateHelper.addMinutes = function (date, minutes) {
                return moment(date)
                    .add(minutes, 'minutes')
                    .toDate();
            };
            DateHelper.addDuration = function (date, duration) {
                var momentDate = moment(date);
                switch (duration.Unit) {
                    case Shared.Contract.Code.DurationUnit.Hours:
                        return momentDate.add(duration.Quantity, 'hours').toDate();
                    case Shared.Contract.Code.DurationUnit.Days:
                        return momentDate.add(duration.Quantity, 'days').toDate();
                    case Shared.Contract.Code.DurationUnit.Weeks:
                        return momentDate.add(7 * duration.Quantity, 'days').toDate();
                    case Shared.Contract.Code.DurationUnit.Months:
                        return momentDate.add(duration.Quantity, 'months').toDate();
                    case Shared.Contract.Code.DurationUnit.Years:
                        return momentDate.add(duration.Quantity, 'years').toDate();
                    default:
                        return date;
                }
            };
            DateHelper.subtractDuration = function (date, duration) {
                var negativeDuration = {
                    Unit: duration.Unit,
                    Quantity: -duration.Quantity,
                };
                return this.addDuration(date, negativeDuration);
            };
            DateHelper.setTime = function (date, time) {
                if (date == null || time == null) {
                    return;
                }
                date.setHours(time.getHours());
                date.setMinutes(time.getMinutes());
            };
            DateHelper.mergeDateAndTime = function (date, time) {
                var momentDate = moment(date).startOf('day');
                if (time) {
                    momentDate.hours(time.getHours());
                    momentDate.minutes(time.getMinutes());
                }
                return momentDate.toDate();
            };
            DateHelper.dateTimeHumanReadible = function (date) {
                return moment(date).format('DD-MM-YYYY HH:mm');
            };
            DateHelper.dateHumanReadible = function (date) {
                return moment(date).format('DD-MM-YYYY');
            };
            DateHelper.timeHumanReadible = function (date) {
                return moment(date).format('HH:mm');
            };
            DateHelper.prototype.today = function () {
                return DateHelper.today();
            };
            DateHelper.prototype.toServerDateTimeString = function (date) {
                return DateHelper.toServerDateTimeString(date);
            };
            DateHelper.prototype.toServerDateString = function (date) {
                return DateHelper.toServerDateString(date);
            };
            DateHelper.prototype.toServerTimeString = function (date) {
                return DateHelper.toServerTimeString(date);
            };
            DateHelper.prototype.serverTimeToDate = function (time) {
                return DateHelper.serverTimeToDate(time);
            };
            DateHelper.prototype.serverDateStringToDate = function (date) {
                return DateHelper.serverDateStringToDate(date);
            };
            DateHelper.prototype.serverDateStringToDateTime = function (date) {
                return DateHelper.serverDateStringToDateTime(date);
            };
            DateHelper.prototype.addDuration = function (date, duration) {
                return DateHelper.addDuration(date, duration);
            };
            DateHelper.prototype.subtractDuration = function (date, duration) {
                return DateHelper.subtractDuration(date, duration);
            };
            return DateHelper;
        }());
        Shared.DateHelper = DateHelper;
        Shared.remeCareSharedModule.factory('dateHelper', function () { return new DateHelper(); });
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
