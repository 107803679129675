module RemeCare.Shared.Filter {
    import DateHelper = Shared.DateHelper;
    var timeSpanDisplayFilter: () => (timeSpan?: Shared.Framework.Model.TimeSpan) => string = () => {
        
        return (timeSpan?: Shared.Framework.Model.TimeSpan) => {
            const timeParts = [];
            
            const defaultValue = '-';
            
            if (!timeSpan)
            {
                return defaultValue;
            }
            
            if (timeSpan.Days > 0)
            {
                timeParts.push(`${timeSpan.Days}d`);
            }
            
            if (timeSpan.Hours > 0)
            {
                timeParts.push(`${timeSpan.Hours}h`);
            }

            if (timeSpan.Minutes > 0)
            {
                timeParts.push(`${timeSpan.Minutes}min`);
            }

            if (timeSpan.Seconds > 0)
            {
                timeParts.push(`${timeSpan.Seconds}sec`);
            }
            
            if (timeParts.length > 0)
            {
                return timeParts.join(' ');
            }
            else
            {
                return defaultValue;
            }
            
        }
    }

    angular.module('RemeCare.Shared').filter('timeSpanDisplayFilter', timeSpanDisplayFilter);
}
