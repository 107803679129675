module RemeCare.Shared.Framework.Model {

    export class IntraDayTiming {
        intraDayTimingId: Shared.Contract.Guid;
        preferredTime: Date;
        preferredTimeWindowBefore: TimeSpan;
        preferredTimeWindowAfter: TimeSpan;
        reminderElapseTimeAfterOpeningWindow: TimeSpan;
        targetValues: Array<TargetValue> = [];

        constructor(serverObject?: Contract.Read.IIntraDayTiming) {
            if (serverObject != null) {
                this.intraDayTimingId = serverObject.Id;
                this.preferredTime = Shared.DateHelper.serverTimeToDate(serverObject.PreferredTime);
                this.preferredTimeWindowBefore = Shared.DateHelper.serverTimeToTimeSpan(serverObject.PreferredTimeWindowBefore);
                this.preferredTimeWindowAfter = Shared.DateHelper.serverTimeToTimeSpan(serverObject.PreferredTimeWindowAfter);
                this.reminderElapseTimeAfterOpeningWindow = Shared.DateHelper.serverTimeToTimeSpan(serverObject.ReminderElapseTimeAfterOpeningWindow);
            }
        }

        toWriteModel(): Contract.Write.IIntraDayTiming {
            return <Contract.Write.IIntraDayTiming>{
                Type: 0,
                Id: this.intraDayTimingId,
                PreferredTime: Shared.DateHelper.toServerTimeString(this.preferredTime),
                PreferredTimeWindowBefore: Shared.DateHelper.timeSpanToServerTimeString(this.preferredTimeWindowBefore),
                PreferredTimeWindowAfter: Shared.DateHelper.timeSpanToServerTimeString(this.preferredTimeWindowAfter),
                ReminderElapseTimeAfterOpeningWindow: Shared.DateHelper.timeSpanToServerTimeString(this.reminderElapseTimeAfterOpeningWindow),
                TargetValues: _(this.targetValues).map(tv => tv.toWriteModel())
            }
        }

        copy(): IntraDayTiming {
            var result = new IntraDayTiming();
            this.setCommonParts(result);
            return result;
        }

        setCommonParts(intraDayTiming: IntraDayTiming) {
            angular.copy(this, intraDayTiming);
            intraDayTiming.targetValues = _(this.targetValues).map(t => t.copy());
        }
    }

    export class IntraDayTimingAdministration extends IntraDayTiming {
        noEatingWindowBefore: Date;
        noEatingWindowAfter: Date;
        administrationTiming: Shared.Contract.IEnumTranslation;

        constructor(serverObject?: Contract.Read.IIntraDayTimingAdministration) {
            super(serverObject);
            if (serverObject != null) {
                this.noEatingWindowAfter = Shared.DateHelper.serverTimeToDate(serverObject.NoEatingWindowAfter);
                this.noEatingWindowBefore = Shared.DateHelper.serverTimeToDate(serverObject.NoEatingWindowBefore);
                this.administrationTiming = serverObject.AdministrationTiming;
            }
        }

        toWriteModel(): Contract.Write.IIntraDayTimingAdministration {
            var result = <Contract.Write.IIntraDayTimingAdministration>super.toWriteModel();
            result.Type = 1;
            result.NoEatingWindowBefore = Shared.DateHelper.toServerTimeString(this.noEatingWindowBefore);
            result.NoEatingWindowAfter = Shared.DateHelper.toServerTimeString(this.noEatingWindowAfter);
            result.AdministrationTiming = this.administrationTiming ? this.administrationTiming.Id : null;
            return result;
        }

        copy(): IntraDayTimingAdministration {
            var result = new IntraDayTimingAdministration();
            super.setCommonParts(result);
            result.noEatingWindowAfter = this.noEatingWindowAfter;
            result.noEatingWindowBefore = this.noEatingWindowBefore;
            result.administrationTiming = this.administrationTiming;
            return result;
        }
    }
}