namespace RemeCare.Shared.Framework.Grid {
    export type SearchFunction<T> = (
        page: number,
        pageSize: number,
        sortField: string,
        sortDirection: string,
        criteria: any,
        count?: boolean,
        nextPage?: boolean
    ) => PromiseLike<Contract.ISearchResult<T>>;

    export type PromiseSearchFunction<T> = (
        page: number,
        pageSize: number,
        sortField: string,
        sortDirection: string,
        criteria: any,
        count?: boolean,
        nextPage?: boolean
    ) => Promise<Contract.ISearchResult<T>>;

    export interface IColumnDefinition extends uiGrid.IColumnDef {
        visibleWhenSmall?: boolean;
    }

    export interface IGridOptions<T> extends uiGrid.IGridOptionsOf<T> {
        enableCellSelection: boolean;
        enableRowSelection: boolean;
        enableSorting: boolean;
        useExternalSorting: boolean;
        enableColumnResizing: boolean;
        enableColumnMenus: boolean;
        data: any;
        onRegisterApi?: (any) => void;
        expandableRowTemplate?: string;
        expandableRowHeight?: number;
        columnDefs?: IColumnDefinition[];
        virtualizationThreshold: number;
        rowTemplate?: string;
        navigationEnabled: boolean;
        searchWhenReady: boolean;
    }

    export interface IPagingOptions {
        pageSize: number;
        currentPage: number;
    }

    export interface IGridScope extends ng.IScope {
        pagingOptions: IPagingOptions;
    }

    export abstract class Grid<T> {
        public pagingOptions: IPagingOptions;

        public sortOptions: {
            field: string;
            direction: string;
        };

        public totalItems: number;

        public searchCriteria: any = {};

        public gridApi: any;

        public pagingType: PagingType;

        public filtered: boolean;

        private urlBinder: Factory.UrlBinder;

        protected constructor(
            public scope: IGridScope,
            protected readonly searchFunction: PromiseSearchFunction<T>,
            public gridOptions: IGridOptions<T>,
            private readonly $timeout: ng.ITimeoutService,
            private readonly uiGridConstants: uiGrid.IUiGridConstants,
            private readonly $window: ng.IWindowService,
            private readonly loadMeasuringSvc: Service.LoadMeasuringService,
            private readonly urlBinderFactory?: Factory.UrlBinderFactory,
            private readonly propertyMappers?: Factory.PropertyMappers,
            private readonly searchCriteriaSetter?: (c) => void
        ) {
            this.init();

            if (this.searchCriteriaSetter) {
                this.searchCriteriaSetter(this.searchCriteria);
            }
        }

        public isSearchFiltered(): boolean {
            for (const key in this.searchCriteria) {
                if (
                    key !== 'page' &&
                    key !== 'pageSize' &&
                    key !== 'sortField' &&
                    key !== 'sortOrder' &&
                    this.searchCriteria.hasOwnProperty(key)
                ) {
                    if (
                        _(this.searchCriteria[key]).isDate() ||
                        ((_(this.searchCriteria[key]).isArray() || _(this.searchCriteria[key]).isObject()) &&
                            !_(this.searchCriteria[key]).isEmpty()) ||
                        (!(_(this.searchCriteria[key]).isArray() || _(this.searchCriteria[key]).isObject()) &&
                            this.searchCriteria[key] != null &&
                            this.searchCriteria[key] !== '')
                    ) {
                        this.filtered = true;
                        return true;
                    }
                }
            }
            this.filtered = false;
            return false;
        }

        public setCriteria(criteria: {}): void {
            angular.copy(criteria, this.searchCriteria);
        }

        public clearCriteria(): void {
            this.clear(this.searchCriteria);
        }

        public search(): JQueryPromise<Contract.ISearchResult<T>> {
            if (this.urlBinder != null) {
                this.searchCriteria.page = this.pagingOptions.currentPage;
                this.searchCriteria.pageSize = this.pagingOptions.pageSize;
                this.searchCriteria.sortOrder = this.sortOptions.direction;
                this.searchCriteria.sortField = this.sortOptions.field;
                this.urlBinder.reflectInUrl(true);
            }
            if (!this.searchFunction) {
                return jQuery.when({ Items: [], Total: 0 });
            } else {
                const deferred = jQuery.Deferred<Contract.ISearchResult<T>>();
                this.executeSearch(false).then(
                    (result) => {
                        deferred.resolve(result);
                    },
                    (error) => {
                        deferred.reject(error);
                    }
                );
                return deferred.promise();
            }
        }

        public currentPageChanged(): void {
            if (this.urlBinder != null) {
                this.searchCriteria.page = this.pagingOptions.currentPage;
                this.urlBinder.reflectInUrl(true);
            }
            this.loadMeasuringSvc.stopMeasuring();
            this.executeSearch(true);
        }

        public setData(newData: T[]): void {
            newData = newData || [];
            this.gridOptions.data = newData;
            this.gridOptions.virtualizationThreshold = newData.length;

            // hacky fix to force a redraw of the grid
            this.$timeout(() => {
                if (this.gridApi) {
                    this.gridApi.core.handleWindowResize();
                }
            });
        }

        public getData(): T[] {
            return this.gridOptions.data;
        }

        public addRow(newRow: T): void {
            this.gridOptions.data.push(newRow);

            // hacky fix to force a redraw of the grid
            this.$timeout(() => {
                if (this.gridApi) {
                    this.gridApi.core.handleWindowResize();
                }
            });
            this.gridOptions.virtualizationThreshold++;
        }

        public hasData(): boolean {
            return this.gridOptions.data.length != null && this.gridOptions.data.length > 0;
        }

        public showColumn(columnName: string): void {
            const col = this.getColumn(columnName);
            if (col != null) {
                col.visible = true;
                this.notifyColumnsChanged();
            }
        }

        public hideColumn(columnName: string): void {
            const col = this.getColumn(columnName);
            if (col != null) {
                col.visible = false;
                this.notifyColumnsChanged();
            }
        }

        public setColumnVisibility(columnIndex: number, visible: boolean, notifyChange = true): void {
            const col = this.gridOptions.columnDefs[columnIndex];
            col.visible = visible;
            if (notifyChange) {
                this.notifyColumnsChanged();
            }
        }

        public changeColumnName(columnIndex: number, displayName: string | Date, notifyChange = true): void {
            const col = this.gridOptions.columnDefs[columnIndex];
            // Date is also fine with cell filters, but uigrid typedefs don't know this
            col.displayName = displayName as string;
            if (notifyChange) {
                this.notifyColumnsChanged();
            }
        }

        public notifyColumnsChanged(): void {
            if (this.gridApi) {
                this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
            }
        }

        public resize(): void {
            this.gridApi.core.handleWindowResize();
        }

        public abstract isPaged(): boolean;

        protected abstract executeSearch(isPagingSearch: boolean): Promise<Contract.ISearchResult<T>>;

        private hasNonEmptyUrlBinder(): boolean {
            return this.urlBinder && !this.urlBinder.isEmpty();
        }

        private async init(): Promise<void> {
            this.sortOptions = this.generateSortOptions(this.gridOptions);
            this.pagingOptions = this.scope.pagingOptions = {
                pageSize: 10,
                currentPage: 1,
            };
            this.gridOptions.data = [];
            this.gridOptions.enableVerticalScrollbar = this.uiGridConstants.scrollbars.NEVER;

            if (this.urlBinderFactory != null) {
                this.urlBinder = this.urlBinderFactory.createUrlBinder(this.searchCriteria, this.propertyMappers);
                await this.urlBinder.bindAllFromUrl();
                if (!this.urlBinder.isEmpty()) {
                    this.pagingOptions.currentPage = this.searchCriteria.page
                        ? this.searchCriteria.page
                        : this.pagingOptions.currentPage;
                    this.pagingOptions.pageSize = this.searchCriteria.pageSize
                        ? this.searchCriteria.pageSize
                        : this.pagingOptions.pageSize;
                    this.sortOptions.direction = this.searchCriteria.sortOrder
                        ? this.searchCriteria.sortOrder
                        : this.sortOptions.direction;
                    this.sortOptions.field = this.searchCriteria.sortField
                        ? this.searchCriteria.sortField
                        : this.sortOptions.field;
                }
            }

            if (this.gridOptions.searchWhenReady || this.hasNonEmptyUrlBinder()) {
                this.search();
            }

            if (this.$window.innerWidth < 1100) {
                this.gridOptions.columnDefs.forEach((cd) => {
                    if (cd.visibleWhenSmall === false) {
                        cd.visible = false;
                    }
                });
            }

            this.scope.$on('panelUncollapsed', () => {
                this.$timeout(() => {
                    if (this.gridApi) {
                        this.gridApi.core.handleWindowResize();
                    }
                });
            });

            this.$window.addEventListener('resize', () => {
                if (this.$window.innerWidth < 1100) {
                    this.dynamicallyChangeGridForSmallScreen();
                } else {
                    this.dynamicallyChangeGridForBigScreen();
                }
                this.refreshGrid();
            });
        }

        private dynamicallyChangeGridForSmallScreen(): void {
            this.gridOptions.columnDefs.forEach((cd) => {
                if (cd.visibleWhenSmall === false) {
                    cd.visible = false;
                }
            });
        }

        private dynamicallyChangeGridForBigScreen(): void {
            this.gridOptions.columnDefs.forEach((cd) => {
                cd.visible = true;
            });
        }

        private refreshGrid(): void {
            if (this.gridApi) {
                this.gridApi.core.refresh();
            }
        }

        private generateSortOptions(gridOptions: IGridOptions<T>) {
            const columnWithSorting = _.find(gridOptions.columnDefs, (cd) => cd.sort && cd.sort.direction);
            if (columnWithSorting) {
                return {
                    field: columnWithSorting.field,
                    direction: columnWithSorting.sort.direction === this.uiGridConstants.ASC ? 'asc' : 'desc',
                };
            } else {
                return {
                    field: null,
                    direction: null,
                };
            }
        }

        private clear(object: any): void {
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    if (_.isArray(object[key])) {
                        object[key] = [];
                    } else if (_.isDate(object[key])) {
                        object[key] = null;
                    } else if (_.isObject(object[key])) {
                        this.clear(object[key]);
                    } else if (_.isFunction(object[key])) {
                        // leave functions in place
                    } else {
                        object[key] = null;
                    }
                }
            }
        }

        private getColumn(columnName: string): IColumnDefinition {
            return _.find(
                this.gridOptions.columnDefs,
                (column) => column.field === columnName || column.name === columnName
            );
        }
    }
}
