namespace RemeCare.Shared.Framework.Model {
    export class CodeSystemCode {
        public codeSystem: CodeSystem;
        public code: string;

        constructor(serverObject?: Contract.Read.ICodeSystemCode) {
            if (serverObject != null) {
                this.codeSystem = new CodeSystem(serverObject.CodeSystem);
                this.code = serverObject.Code;
            }
        }

        public toServerWrite(): Contract.Write.ICodeSystemCode {
            return {
                code: this.code,
                codeSystemId: this.codeSystem.id,
            };
        }

        public copy(): CodeSystemCode {
            const result = new CodeSystemCode();
            result.code = this.code;
            result.codeSystem = this.codeSystem;
            return result;
        }
    }
}
