var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var RemeCareGridController = /** @class */ (function () {
                    function RemeCareGridController($element, $timeout) {
                        this.$element = $element;
                        this.$timeout = $timeout;
                        this.shown = false;
                        this.checkTimeoutMs = 300;
                        this.destroyed = false;
                    }
                    RemeCareGridController.prototype.$onInit = function () {
                        var _this = this;
                        setTimeout(function () { return _this.loopVisibilityCheck(); }, this.checkTimeoutMs);
                    };
                    RemeCareGridController.prototype.$onDestroy = function () {
                        this.destroyed = true;
                    };
                    /**
                     * Attempt at working around grids not rendering properly, having all columns collapsed
                     */
                    RemeCareGridController.prototype.loopVisibilityCheck = function () {
                        var _this = this;
                        if (this.destroyed) {
                            return;
                        }
                        this.checkVisibility();
                        /**
                         * setTimeout instead of $timeout to avoid triggering $scope.$apply when there are no changes
                         * Using $timeout for doing the visible check (often) would have a big performance impact
                         * Impact is minimal now (3 if-checks each 300ms for each grid)
                         * Using $timeout in the actual change (when becoming visible) ensures properly propagating the $scope changes
                         * https://stackoverflow.com/a/39311081
                         */
                        setTimeout(function () { return _this.loopVisibilityCheck(); }, this.checkTimeoutMs);
                    };
                    RemeCareGridController.prototype.checkVisibility = function () {
                        var _this = this;
                        var visible = this.isVisible();
                        if (this.shown === visible) {
                            return;
                        }
                        this.shown = visible;
                        if (visible) {
                            /**
                             * Use $timeout here to have angular properly detect potential $scope changes
                             */
                            this.$timeout(function () {
                                if (_this.grid && _this.grid.gridApi) {
                                    _this.grid.resize();
                                }
                            });
                        }
                    };
                    RemeCareGridController.prototype.isVisible = function () {
                        return this.$element.width() !== 0 || this.$element.height() !== 0;
                    };
                    return RemeCareGridController;
                }());
                Shared.remeCareSharedModule.component('rcGrid', {
                    controller: RemeCareGridController,
                    bindings: {
                        grid: '<',
                        parent: '<?',
                    },
                    template: [
                        '$attrs',
                        '$templateCache',
                        function ($attrs, $templateCache) {
                            var template = $templateCache.get('framework/directives/grid/remecareGrid.html');
                            if (!('expandable' in $attrs)) {
                                template = template.replace('ui-grid-expandable', '');
                            }
                            if (!('autoResize' in $attrs)) {
                                template = template.replace('ui-grid-auto-resize', '');
                            }
                            return template;
                        },
                    ],
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
