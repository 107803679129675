namespace RemeCare.Shared.Framework.Grid {
    export interface INavigationParameters {
        state: string;
        parameters: {};
        nonEntityParams: {};
    }

    export class IconSize {
        public static extraSmall: string = ' ';
        public static small: string = 'fa-lg';
        public static medium: string = 'fa-2x';
        public static large: string = 'fa-3x';
        public static extraLarge: string = 'fa-4x';
        public static mega: string = 'fa-5x';
    }

    export class SearchGridBuilder<T> {
        public gridApi: any;

        public rowFunctions: { removeRow(index: number): void };
        public select: (result: T) => any;

        public handle: (data: any) => any;

        public onDelete: (deleted: T) => any;
        public searchCriteriaSetter: (c) => void;
        public confirmDelete: (toBeDeleted: T) => PromiseLike<boolean>;
        private multiLineRowTemplate: string =
            `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" ng-style="grid.api.remeCare.getRowStyle(grid, row.entity)"  ` +
            `ui-grid-one-bind-id-grid="rowRenderIndex + \'-\' + col.uid + \'-cell\'" class="ui-grid-cell ui-grid-multi-line" ` +
            `ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" role="{{col.isRowHeader ? \'rowheader\' : \'gridcell\'}}" ui-grid-cell></div>`;

        private editButtonWithFunctionTemplate: string =
            '<div class="text-center vertical-center">' +
            '<button type="button" ng-click="grid.api.remeCare.editRow(grid, row.entity)" ng-disabled="grid.api.remeCare.editDisabled(row.entity)" class="btn btn-primary btn-sm">' +
            '<i class="glyphicon glyphicon-$$buttonIcon$$"></i>' +
            '</button>' +
            '</div>';

        private showEditButtonWithFunctionTemplate: string =
            '<div class="text-center vertical-center">' +
            '<button type="button" ng-click="grid.api.remeCare.editRow(grid, row.entity)" ng-hide="grid.api.remeCare.editDisabled(row.entity)" class="btn btn-primary btn-sm">' +
            '<i class="glyphicon glyphicon-pencil"></i>' +
            '</button>' +
            '<button type="button" ng-click="grid.api.remeCare.showDetail(row.entity)" ng-show="grid.api.remeCare.editDisabled(row.entity)" class="btn btn-primary btn-sm">' +
            '<i class="glyphicon glyphicon-eye-open"></i>' +
            '</button>' +
            '</div>';

        private showEditNoButtonWithFunctionTemplate: string =
            '<div class="text-center vertical-center">' +
            '<button type="button" ng-click="grid.api.remeCare.showDetail(row.entity)" ng-hide="grid.api.remeCare.editDisabled(row.entity)" class="btn btn-primary btn-sm">' +
            '<i class="glyphicon glyphicon-pencil"></i>' +
            '</button>' +
            '<button type="button" ng-click="grid.api.remeCare.showDetail(row.entity)" ng-hide="!grid.api.remeCare.editDisabled(row.entity) || grid.api.remeCare.showDisabled(row.entity)" class="btn btn-primary btn-sm">' +
            '<i class="glyphicon glyphicon-eye-open"></i>' +
            '</button>' +
            '</div>';

        private deleteShowButtonTemplate: string =
            '<div class="text-center vertical-center">' +
            '<button type="button" class="btn btn-danger btn-sm" ng-hide="grid.api.remeCare.showDisabled(row.entity)" ng-click="grid.api.remeCare.deleteRow(grid, row.entity)"  ><i class="glyphicon glyphicon-trash"></i></button>' +
            '</div>';

        private selectButtonTemplate: string =
            '<div class="text-center vertical-center ngCellText">' +
            '<button type="button" ng-click="grid.api.remeCare.selectEntity(row.entity)" class="btn btn-primary btn-xs">' +
            '<span>Select</span>' +
            '</button>' +
            '</div>';

        private detailButtonTemplate: string =
            '<div class="text-center vertical-center">' +
            '<button type="button" ng-click="grid.api.remeCare.showDetail(row.entity)" class="btn btn-default btn-sm">' +
            '<i class="glyphicon glyphicon-search"></i>' +
            '</button>' +
            '</div>';

        private columnDefs = [];

        private gridOptions: IGridOptions<T>;

        private gridId: string;

        private editCallback: (x: T) => void;
        private editFunction: (x: T) => T;
        private editFunctionWithPromise: (x: T) => PromiseLike<T>;
        private editDisabledFunction: (x: T) => boolean;
        private showDisabledFunction: (x: T) => boolean;
        private showButtonFunction: (x: T) => boolean;

        private deleteDisabledFunction: (x: T) => boolean;
        private upDownDisabledFunction: (x: T) => boolean;

        private conditionalActionFunction: (x: T) => T;
        private conditionalActionFunctionWithPromise: (x: T) => PromiseLike<T>;

        private showDetail: (x: T) => any;

        private onRowsSwitched: (row1: T, row2: T) => any;

        private navigation: INavigationParameters;
        private navigationAction: (data: T) => any;

        private isPrevNextGrid: boolean;

        private bindToUrl: boolean;
        private propertyMappers: Factory.PropertyMappers;

        constructor(
            private readonly uiGridConstants: uiGrid.IUiGridConstants,
            private readonly searchFunction: PromiseSearchFunction<T>,
            private readonly scope: IGridScope,
            private readonly $timeout: ng.ITimeoutService,
            private readonly urlBinderFactory: Factory.UrlBinderFactory,
            private readonly $window: ng.IWindowService,
            private readonly loadMeasuringSvc: Service.LoadMeasuringService,
            private readonly $state: ng.ui.IStateService
        ) {
            this.gridOptions = {
                enableSorting: true,
                enableCellSelection: false,
                enableRowSelection: false,
                useExternalSorting: true,
                enableColumnResizing: true,
                enableHorizontalScrollbar: false,
                enableVerticalScrollbar: false,
                enableColumnMenus: false,
                data: [],
                virtualizationThreshold: 25,
                navigationEnabled: false,
                searchWhenReady: false,
            };
        }

        public setIsPrevNextGrid(): SearchGridBuilder<T> {
            this.isPrevNextGrid = true;
            return this;
        }

        /** When the grid is ready to be used, a search will be immediately performed */
        public setSearchWhenReady(): SearchGridBuilder<T> {
            this.gridOptions.searchWhenReady = true;
            return this;
        }

        public setBindToUrl(bindToUrl = true, propertyMappers?: Factory.PropertyMappers): SearchGridBuilder<T> {
            this.bindToUrl = bindToUrl;
            this.propertyMappers = propertyMappers;
            return this;
        }

        public setMultiLine(gridId: string): SearchGridBuilder<T> {
            this.gridOptions.rowTemplate = this.multiLineRowTemplate;
            this.gridId = gridId;
            return this;
        }

        public setMultiLineWithTodayLine(gridId: string, dateField: string): SearchGridBuilder<T> {
            this.gridOptions.rowTemplate = this.multiLineWithTodayLineRowTemplate(dateField);
            this.gridId = gridId;
            return this;
        }

        public setExternalSorting(externalSorting: boolean): SearchGridBuilder<T> {
            this.gridOptions.useExternalSorting = externalSorting;
            this.gridOptions.enableSorting = externalSorting;
            return this;
        }

        public setSorting(enableSorting: boolean): SearchGridBuilder<T> {
            this.gridOptions.enableSorting = enableSorting;
            return this;
        }

        public setData(dataSelector: string): SearchGridBuilder<T> {
            this.gridOptions.data = dataSelector;
            return this;
        }

        public addColumn(
            field: string,
            displayName: string,
            options?: any,
            extraInfo?: (object: T) => string
        ): SearchGridBuilder<T> {
            const column = this.createColumn(field, displayName, options, null, extraInfo);
            this.columnDefs.push(column);
            return this;
        }

        public addTranslateColumn(
            field: string,
            displayName: string,
            options?: any,
            extraInfo?: (object: T) => string
        ): SearchGridBuilder<T> {
            options = options || {};
            options.cellFilter = 'translate';
            const column = this.createColumn(field, displayName, options, null, extraInfo);
            this.columnDefs.push(column);
            return this;
        }

        public addEnumColumn(
            field: string,
            displayName: string,
            enumType: string,
            options?: any,
            extraInfo?: (object: T) => string
        ): SearchGridBuilder<T> {
            options = options || {};
            options.cellFilter = `enum : '${enumType}'`;
            return this.addColumn(field, displayName, options, extraInfo);
        }

        public addColumnWithTooltipHeader(field: string, displayName: string, options?: any): SearchGridBuilder<T> {
            const column = this.createColumn(field, displayName, options);
            column.headerCellTemplate = this.headerCellTemplateWithTooltip();
            this.columnDefs.push(column);
            return this;
        }

        public addDateColumn(
            field: string,
            displayName: string,
            dateFormat: string,
            options?: any
        ): SearchGridBuilder<T> {
            options = options || {};
            options.cellFilter = `date : '${dateFormat}'`;
            const column = this.createColumn(field, displayName, options);

            column.width = column.width || 150;
            this.columnDefs.push(column);
            return this;
        }

        public addDateRangeColumn(
            field1: string,
            field2: string,
            displayName: string,
            dateFormat: string,
            options?: any
        ): SearchGridBuilder<T> {
            options = options || {};
            options.cellFilter = `date : '${dateFormat}'`;
            const column: any = options;
            column.field1 = field1;
            column.field2 = field2;
            column.name = displayName;
            column.displayName = displayName;
            column.headerCellFilter = 'translate';
            column.cellTemplate = this.dateRangeCellTemplate(field1, field2, column.cellFilter);
            column.width = column.width || 150;
            this.columnDefs.push(column);

            return this;
        }

        public addUtcDateColumn(
            field: string,
            displayName: string,
            dateFormat: string,
            options?: any
        ): SearchGridBuilder<T> {
            options = options || {};
            options.cellFilter = `displayUtcDateTimeFilter : '${dateFormat}'`;
            const column = this.createColumn(field, displayName, options);
            column.width = options.width || 150;
            this.columnDefs.push(column);
            return this;
        }

        public addIconColumn(
            displayName: string,
            iconFunction: (object: T) => string,
            action?: (data: T) => any,
            size?: string
        ): SearchGridBuilder<T> {
            size = size || IconSize.medium;
            const template = this.iconTemplate(size);
            const column = {
                name: 'icon-' + displayName,
                displayName,
                headerCellFilter: 'translate',
                cellTemplate: template,
                width: 100,
                enableSorting: false,
                icon: iconFunction,
                action,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addIconRefColumn(
            refFunction: (object: T) => string,
            displayName: string,
            iconFunction: (object: T) => string
        ): SearchGridBuilder<T> {
            const template = this.iconRefTemplate();
            const column = {
                ref: refFunction,
                name: 'icon',
                displayName,
                cellTemplate: template,
                width: 80,
                enableSorting: false,
                icon: iconFunction,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addPrioColumn(field: string, displayName: string, textField: string): SearchGridBuilder<T> {
            const cellTemplate = this.prioColumnTemplate(field, textField);
            const column = {
                cellTemplate,
                field,
                displayName,
                headerCellFilter: 'translate',
                width: 80,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addConditionallyStyledColumn(
            field: string,
            displayName: string,
            switchField: string,
            classConditions: any,
            options?: any
        ): SearchGridBuilder<T> {
            const conditions = [];
            for (const key in classConditions) {
                if (classConditions.hasOwnProperty(key)) {
                    let expression = "'" + key + "': ";
                    expression += 'row.entity.' + switchField + ' == ' + classConditions[key];
                    conditions.push(expression);
                }
            }
            const column = this.createColumn(field, displayName, options, conditions);
            this.columnDefs.push(column);
            return this;
        }

        public addColourColumn(field: string, displayName: string, editable?: boolean): SearchGridBuilder<T> {
            const cellTemplate = this.colourColumnTemplate(field, editable);
            const column = {
                cellTemplate,
                field,
                displayName,
                headerCellFilter: 'translate',
                width: editable ? 120 : 100,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addBoolEditColumn(field: string, displayName: string, options?: any): SearchGridBuilder<T> {
            const cellTemplate = this.boolColumnEditTemplate(field);
            const column = {
                cellTemplate,
                field,
                displayName,
                headerCellFilter: 'translate',
            };
            this.columnDefs.push(column);
            return this;
        }

        public addConditionalBoolEditColumn(
            field: string,
            displayName: string,
            editDisabledFunction: (editDisabledFunction: T) => boolean
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;

            const cellTemplate = this.conditionalBoolColumnEditTemplate(field);
            const column = {
                cellTemplate,
                field,
                displayName,
                headerCellFilter: 'translate',
            };
            this.columnDefs.push(column);
            return this;
        }

        public addSimpleDropDownEditColumn(
            field: string,
            displayName: string,
            dropDownOptions: any[]
        ): SearchGridBuilder<T> {
            const cellTemplate = this.simpleDropDownColumnEditTemplate(field);
            const column = {
                cellTemplate,
                field,
                displayName,
                headerCellFilter: 'translate',
                editDropdownOptionsArray: dropDownOptions,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addCheckBoxColumn(
            displayName: string,
            field: string,
            editDisabledFunction: (editDisabledFunction: T) => boolean = (x) => false
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            const cellTemplate = this.checkBoxColumnTemplate(field);
            const column = {
                cellTemplate,
                name: 'checkbox',
                headerCellFilter: 'translate',
                displayName,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addNumberColumn(
            field: string,
            displayName: string,
            editDisabledFunction: (editDisabledFunction: T) => boolean = (x) => false
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            const cellTemplate = this.numberColumnTemplate(field);
            const column = {
                cellTemplate,
                headerCellFilter: 'translate',
                displayName,
                field,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addTextColumn(
            field: string,
            displayName: string,
            editDisabledFunction: (editDisabledFunction: T) => boolean = (x) => false
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            const cellTemplate = this.textColumnTemplate(field);
            const column = {
                cellTemplate,
                headerCellFilter: 'translate',
                displayName,
                field,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addDropdownEditColumn(
            field: string,
            displayName: string,
            dropDownOptions: any[],
            value: string,
            label: string,
            options?: any
        ): SearchGridBuilder<T> {
            const cellTemplate = this.dropDownColumnEditTemplate(field, value, label);
            const column = {
                cellTemplate,
                field,
                displayName,
                headerCellFilter: 'translate',
                editDropdownIdLabel: value,
                editDropdownValueLabel: label,
                editDropdownOptionsArray: dropDownOptions,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addLinkColumn(field: string, displayName: string, link: string, options?: any): SearchGridBuilder<T> {
            const column = this.createColumn(field, displayName, options);
            column.cellTemplate = this.linkCellTemplate(field, column.cellFilter, link);
            this.columnDefs.push(column);
            return this;
        }

        public addNavigationColumn(
            icon: string,
            state: string,
            parameters: {},
            nonEntityParams?: {}
        ): SearchGridBuilder<T> {
            this.navigation = { state, parameters, nonEntityParams };
            this.gridOptions.navigationEnabled = true;
            const cellTemplate = this.navigationTemplate(icon, this.navigation);
            const column = { cellTemplate, name: '', field: this.navigation.state, width: 80 };
            this.columnDefs.push(column);
            return this;
        }

        public addExternalLinkColumn(
            displayName: string,
            displayText: string,
            state: string,
            target: string,
            parameters: {},
            nonEntityParams?: {}
        ): SearchGridBuilder<T> {
            this.navigation = { state, parameters, nonEntityParams };
            const template = this.externalLinkTemplate(this.navigation, target, displayText);
            const column = {
                name: 'externalLink',
                displayName,
                cellTemplate: template,
                headerCellFilter: 'translate',
            };
            this.columnDefs.push(column);
            return this;
        }

        public addConditionalShowEditNoButtonNavigationColumn(
            editDisabledFunction: (editDisabledFunction: T) => boolean,
            showDisabledFunction: (showDisabledFunction: T) => boolean,
            state: string,
            parameters: {},
            nonEntityParams?: {}
        ): SearchGridBuilder<T> {
            this.navigation = { state, parameters, nonEntityParams };
            this.gridOptions.navigationEnabled = true;
            this.editDisabledFunction = editDisabledFunction;
            this.showDisabledFunction = showDisabledFunction;
            const template = this.showEditNoButtonNavigationTemplate(this.navigation);
            const column = { name: 'edit', displayName: '', cellTemplate: template, width: 80, enableSorting: false };
            this.columnDefs.push(column);

            return this;
        }

        public addConditionalNavigationButtonAndEditButtonColumn(
            showNavigationButton: (showNavigationButton: T) => boolean,
            showEditButton: (showEditButton: T) => boolean,
            navigationButtonState: string,
            navigationButtonParameters: {},
            editButtonState: string,
            editButtonParameters: {}
        ): SearchGridBuilder<T> {
            const navigationButtonNavParameters = {
                state: navigationButtonState,
                parameters: navigationButtonParameters,
                nonEntityParams: null,
            };
            const editButtonNavParameters = {
                state: editButtonState,
                parameters: editButtonParameters,
                nonEntityParams: null,
            };
            this.navigation = editButtonNavParameters;
            this.gridOptions.navigationEnabled = true;
            this.showButtonFunction = showNavigationButton;
            this.editDisabledFunction = showEditButton;
            const template = this.navigationButtonAndEditButtonNavigationTemplate(
                navigationButtonNavParameters,
                editButtonNavParameters
            ); //
            const column = {
                name: 'navigationButton',
                displayName: '',
                cellTemplate: template,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);

            return this;
        }

        public addActionColumn(
            buttonIcon: string,
            action: (data: T) => any,
            enableNavigation?: boolean
        ): SearchGridBuilder<T> {
            const actionButtonTemplate = this.actionButtonTemplate(buttonIcon);
            const column = {
                name: 'action',
                displayName: '',
                cellTemplate: actionButtonTemplate,
                width: 80,
                enableSorting: false,
                action,
            };
            this.columnDefs.push(column);
            if (enableNavigation) {
                this.gridOptions.navigationEnabled = true;
                this.navigationAction = action;
            }

            return this;
        }

        public addAction(action: (data: T) => any): SearchGridBuilder<T> {
            this.gridOptions.navigationEnabled = true;
            this.navigationAction = action;
            return this;
        }

        public addActionOnScopeColumn(func: string, displayName: string, options?: any): SearchGridBuilder<T> {
            const template = this.actionOnScopeTemplate(func);
            const column = {
                name: 'action' + displayName,
                displayName,
                headerCellFilter: 'translate',
                cellTemplate: template,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addLinkActionColumn(
            field: string,
            displayName: string,
            func: string,
            options?: any
        ): SearchGridBuilder<T> {
            const column = this.createColumn(field, displayName, options);
            column.cellTemplate = this.linkActionCellTemplate(field, column.cellFilter, func);
            this.columnDefs.push(column);
            return this;
        }

        public addCustomColumn(
            displayName: string,
            template: string,
            callback: (data: any) => any,
            options?: any,
            tooltipHeader?: boolean,
            headerCellFilter?: string
        ): SearchGridBuilder<T> {
            this.handle = callback;
            const column: any = options == null ? {} : options;
            column.name = 'custom' + this.columnDefs.length;
            column.cellTemplate = template;
            column.displayName = displayName;
            column.headerCellFilter = headerCellFilter ? headerCellFilter : 'translate';
            if (tooltipHeader) {
                column.headerCellTemplate = this.headerCellTemplateWithTooltip();
            }

            this.columnDefs.push(column);
            return this;
        }

        public addBoolColumn(
            field: string,
            displayName: string,
            reverse?: boolean,
            showFalse?: boolean,
            options?: any
        ): SearchGridBuilder<T> {
            if (showFalse == null) {
                showFalse = true;
            }
            const cellTemplate = this.boolColumnTemplate(field, showFalse, reverse);
            const column: any = options == null ? {} : options;
            column.name = 'bool' + field;
            column.cellTemplate = cellTemplate;
            column.displayName = displayName;
            column.headerCellFilter = 'translate';
            column.width = column.width == null ? 80 : column.width;
            column.field = field;
            this.columnDefs.push(column);
            return this;
        }

        public addEditButtonWithFunctionColumn(editFunction: (t: T) => T, buttonIcon?: string): SearchGridBuilder<T> {
            this.editFunction = editFunction;
            this.addEditButtonFunctionColumn();
            return this;
        }

        public addConditionalEditButtonWithPromiseFunctionColumn(
            editFunction: (editFunction: T) => PromiseLike<T>,
            editDisabledFunction: (editDisabledFunction: T) => boolean,
            buttonIcon?: string
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            return this.addEditButtonWithPromiseFunctionColumn(editFunction);
        }

        public addConditionalShowEditButtonWithPromiseFunctionColumn(
            rowDetailSelectedCallback: (selected: T) => any,
            editFunction: (selected: T) => PromiseLike<T>,
            editDisabledFunction: (editDisabledFunction: T) => boolean
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            this.showDetail = rowDetailSelectedCallback;
            this.editFunctionWithPromise = editFunction;

            const editButtonWithFunctionTemplate = this.showEditButtonWithFunctionTemplate;

            const column = {
                name: 'edit',
                displayName: '',
                cellTemplate: editButtonWithFunctionTemplate,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);

            return this;
        }

        public addConditionalShowEditNoButtonFunctionColumn(
            rowDetailSelectedCallback: (selected: T) => any,
            editDisabledFunction: (editDisabledFunction: T) => boolean,
            showDisabledFunction: (showDisabledFunction: T) => boolean
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            this.showDisabledFunction = showDisabledFunction;
            this.showDetail = rowDetailSelectedCallback;

            const editButtonWithFunctionTemplate = this.showEditNoButtonWithFunctionTemplate;

            const column = {
                name: 'edit',
                displayName: '',
                cellTemplate: editButtonWithFunctionTemplate,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);

            return this;
        }

        public addEditButtonWithPromiseFunctionColumn(
            editFunction: (x: T) => PromiseLike<T>,
            buttonIcon?: string
        ): SearchGridBuilder<T> {
            this.editFunctionWithPromise = editFunction;
            this.addEditButtonFunctionColumn(buttonIcon);
            return this;
        }

        public addCheckBoxColumnFunction(
            displayName: string,
            field: string,
            editDisabledFunction: (editDisabledFunction: T) => boolean,
            editFunction: (x: T) => T
        ): SearchGridBuilder<T> {
            this.editDisabledFunction = editDisabledFunction;
            this.editFunction = editFunction;
            const cellTemplate = this.checkBoxColumnWithFunctionTemplate(field);
            const column = {
                cellTemplate,
                name: 'checkbox',
                headerCellFilter: 'translate',
                displayName,
                width: 80,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addConditionalDeleteButtonColumn(
            deleteDisabledFunction: (toBeDeleted: T) => boolean,
            onDeleteCallback?: (deleted: T) => any,
            confirmDelete?: (toBeDeleted: T) => PromiseLike<boolean>,
            info?: string
        ): SearchGridBuilder<T> {
            this.deleteDisabledFunction = deleteDisabledFunction;
            return this.addDeleteButtonColumn(onDeleteCallback, confirmDelete, info);
        }

        public addConditionalShowDeleteButtonColumn(
            showDisabledFunction: (showDisabledFunction: T) => boolean,
            onDeleteCallback?: (deleted: T) => any
        ): SearchGridBuilder<T> {
            this.showDisabledFunction = showDisabledFunction;
            this.onDelete = onDeleteCallback;
            const column = {
                name: 'delete',
                displayName: '',
                cellTemplate: this.deleteShowButtonTemplate,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addDeleteButtonColumn(
            onDeleteCallback?: (deleted: T) => any,
            confirmDelete?: (toBeDeleted: T) => PromiseLike<boolean>,
            info?: string
        ): SearchGridBuilder<T> {
            this.onDelete = onDeleteCallback;
            this.confirmDelete = confirmDelete;
            const column = {
                name: 'delete',
                displayName: '',
                cellTemplate: this.deleteButtonTemplate(info),
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addSelectButtonColumn(resultSelectedCallback: (selected: T) => any): SearchGridBuilder<T> {
            this.select = resultSelectedCallback;
            const column = {
                name: 'select',
                displayName: '',
                cellTemplate: this.selectButtonTemplate,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addShowDetailButtonColumn(rowDetailSelectedCallback: (selected: T) => any): SearchGridBuilder<T> {
            this.showDetail = rowDetailSelectedCallback;
            const column = {
                name: 'showDetail',
                displayName: '',
                cellTemplate: this.detailButtonTemplate,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addUpDownButtonColumn(
            orderField: string,
            onRowsSwitched?: (row1: T, row2: T) => any
        ): SearchGridBuilder<T> {
            this.onRowsSwitched = onRowsSwitched;
            const column = {
                name: 'upDown',
                displayName: '',
                cellTemplate: this.upDownButtonTemplate(orderField),
                width: 100,
                enableSorting: false,
            };
            this.columnDefs.push(column);
            return this;
        }

        public addConditionalUpDownButtonColumn(
            upDownDisabledFunction: (upDownInitiated: T) => boolean,
            orderField: string,
            onRowsSwitched?: (row1: T, row2: T) => any
        ): SearchGridBuilder<T> {
            this.upDownDisabledFunction = upDownDisabledFunction;
            this.onRowsSwitched = onRowsSwitched;
            const column = {
                name: 'upDown',
                displayName: '',
                cellTemplate: this.upDownButtonTemplate(orderField),
                width: 100,
                enableSorting: false,
            };
            this.columnDefs.push(column);
            return this;
        }

        public setRowDetailsTemplate(template: string, height: number): SearchGridBuilder<T> {
            this.gridOptions.expandableRowTemplate = template;
            this.gridOptions.expandableRowHeight = height;
            return this;
        }

        public setRowConditionalClasses(switchField: string, classConditions: any): SearchGridBuilder<T> {
            const conditions = [];

            for (const key in classConditions) {
                if (classConditions.hasOwnProperty(key)) {
                    let expression = "'" + key + "': ";
                    expression += 'row.entity.' + switchField + ' == ' + classConditions[key];
                    conditions.push(expression);
                }
            }

            this.gridOptions.rowTemplate = this.rowTemplate(conditions);
            return this;
        }

        public setSearchCriteria(setter: (c) => void): SearchGridBuilder<T> {
            this.searchCriteriaSetter = setter;
            return this;
        }

        public build(): Grid<T> {
            this.gridOptions.columnDefs = this.columnDefs;
            const grid = this.isPrevNextGrid
                ? new PrevNextPagedGrid<T>(
                      this.scope,
                      this.searchFunction,
                      this.gridOptions,
                      this.$timeout,
                      this.uiGridConstants,
                      this.$window,
                      this.loadMeasuringSvc,
                      this.bindToUrl ? this.urlBinderFactory : null,
                      this.propertyMappers,
                      this.searchCriteriaSetter
                  )
                : new FullyPagedGrid<T>(
                      this.scope,
                      this.searchFunction,
                      this.gridOptions,
                      this.$timeout,
                      this.uiGridConstants,
                      this.$window,
                      this.loadMeasuringSvc,
                      this.bindToUrl ? this.urlBinderFactory : null,
                      this.propertyMappers,
                      this.searchCriteriaSetter
                  );
            this.configureApi(grid);
            return grid;
        }

        private multiLineWithTodayLineRowTemplate(dateField: string): string {
            let result = `<div class="today-line" ng-if='grid.api.remeCare.isTodayBetweenDates(grid, row.entity, "${dateField}")'><span>{{ 'General.Today' | translate }}</span></div>`;
            result += this.multiLineRowTemplate;
            return result;
        }

        private actionButtonTemplate(buttonIcon: string): string {
            const template =
                '<div class="text-center vertical-center">' +
                '<button type="button" ng-click="col.colDef.action(row.entity)" class="btn btn-default btn-sm pagination-centered">' +
                '<span class="glyphicon glyphicon-' +
                buttonIcon +
                '"></span>' +
                '</button>' +
                '</div>';
            return template;
        }

        private iconTemplate(size: string): string {
            const template =
                '<div class="text-center vertical-center">' +
                '<span ng-click="col.colDef.action(row.entity)" ng-class="{\'link link-color\':col.colDef.action}" class="fa ' +
                size +
                ' fa-{{col.colDef.icon(row.entity)}}"></span>' +
                '</div>';
            return template;
        }

        private iconRefTemplate(): string {
            const template =
                '<div class="text-center vertical-center">' +
                `<a target="_blank" href="{{col.colDef.ref(row.entity)}}">` +
                '<span class="fa fa-2x fa-{{col.colDef.icon(row.entity)}}"></span>' +
                '</a>' +
                '</div>';
            return template;
        }

        private getParamsString(parameters: {}, nonEntityParams: {}): string {
            if (!parameters && !nonEntityParams) {
                return '{}';
            }
            const params = [];
            for (const key in parameters || {}) {
                if (parameters.hasOwnProperty(key)) {
                    params.push(`${key}: row.entity.${parameters[key]}`);
                }
            }
            for (const key in nonEntityParams || {}) {
                if (nonEntityParams.hasOwnProperty(key)) {
                    params.push(`${key}: grid.appScope.${nonEntityParams[key]}`);
                }
            }
            const paramsString = `{${params.join(', ')}}`;
            return paramsString;
        }

        private navigationTemplate(buttonIcon: string, navigation: INavigationParameters): string {
            const paramsString = this.getParamsString(navigation.parameters, navigation.nonEntityParams);
            const template =
                '<div class="text-center vertical-center">' +
                `<a ui-sref="${
                    navigation.state
                }(${paramsString})" class="btn btn-default btn-sm pagination-centered">` +
                '<i class="glyphicon glyphicon-' +
                buttonIcon +
                '"></i>' +
                '</a>' +
                '</div>';
            return template;
        }

        private navigationButtonAndEditButtonNavigationTemplate(
            navigationButtonParameters: INavigationParameters,
            editButtonParameters: INavigationParameters
        ): string {
            const navigationButtonParamsString = this.getParamsString(
                navigationButtonParameters.parameters,
                navigationButtonParameters.nonEntityParams
            );
            const editButtonParamsString = this.getParamsString(
                editButtonParameters.parameters,
                editButtonParameters.nonEntityParams
            );
            return (
                '<div class="vertical-center">' +
                '<div class="text-right">' +
                `<a ui-sref="${
                    navigationButtonParameters.state
                }(${navigationButtonParamsString})" ng-hide="!grid.api.remeCare.showButton(row.entity)" class="mr-1 btn btn-primary btn-sm">` +
                '<i class="glyphicon glyphicon-user"></i>' +
                '</a>' +
                `<a ui-sref="${
                    editButtonParameters.state
                }(${editButtonParamsString})" ng-hide="grid.api.remeCare.editDisabled(row.entity)" class="mr-1 btn btn-primary btn-sm">` +
                '<i class="glyphicon glyphicon-pencil"></i>' +
                '</a>' +
                `<a ui-sref="${
                    editButtonParameters.state
                }(${editButtonParamsString})" ng-hide="!grid.api.remeCare.editDisabled(row.entity) || grid.api.remeCare.showDisabled(row.entity)" class="mr-1 btn btn-primary btn-sm">` +
                '<i class="glyphicon glyphicon-eye-open"></i>' +
                '</a>' +
                '</div>' +
                '</div>'
            );
        }

        private showEditNoButtonNavigationTemplate(navigation: INavigationParameters): string {
            const paramsString = this.getParamsString(navigation.parameters, navigation.nonEntityParams);
            return (
                '<div class="text-center vertical-center">' +
                `<a ui-sref="${
                    navigation.state
                }(${paramsString})" ng-hide="grid.api.remeCare.editDisabled(row.entity)" class="btn btn-primary btn-sm">` +
                '<i class="glyphicon glyphicon-pencil"></i>' +
                '</a>' +
                `<a ui-sref="${
                    navigation.state
                }(${paramsString})" ng-hide="!grid.api.remeCare.editDisabled(row.entity) || grid.api.remeCare.showDisabled(row.entity)" class="btn btn-primary btn-sm">` +
                '<i class="glyphicon glyphicon-eye-open"></i>' +
                '</a>' +
                '</div>'
            );
        }

        private tooltip(content: string): string {
            return `bs-tooltip title="${content ? content : ''}"`;
        }

        private tooltipField(fieldName: string, filter?: string): string {
            let result = `bs-tooltip title="{{ row.entity.${fieldName}`;
            if (filter != null) {
                result += ' | ' + filter.replace(/"/g, "'");
            }
            result += ' }}"';
            return result;
        }

        private externalLinkTemplate(navigation: INavigationParameters, target: string, displayText: string): string {
            const paramsString = this.getParamsString(navigation.parameters, navigation.nonEntityParams);
            const cellTemplate =
                ` <a ui-sref="${
                    navigation.state
                }(${paramsString})" target="${target}" class="ui-grid-cell-contents link-colored" data-container="body" data-delay="500,100">` +
                `{{ '${displayText}' | translate }}` +
                `</a>` +
                `</a>`;

            return cellTemplate;
        }

        private prioColumnTemplate(prioField: string, textField: string): string {
            const template =
                `<div style="padding-top:5px;" ng-class="{link: grid.options.navigationEnabled}" ng-click="grid.api.remeCare.navigate(row.entity)" class="text-center vertical-center" data-container="body" data-delay="500,100" ${this.tooltipField(
                    textField
                )}>` +
                `<rc-priority-circle priority="row.entity.${prioField}" width="24" radius="12"></rc-priority-circle>` +
                '</div>';
            return template;
        }

        private colourColumnTemplate(fieldName: string, editable: boolean): string {
            let template = `<div class="text-center vertical-center" data-container="body" data-delay="500,100" ${this.tooltipField(
                fieldName
            )}>`;
            if (!editable) {
                template += `<span class="grid-colour" ng-style="{'background-color': row.entity.${fieldName}}" colorpicker colorpicker-fixed-position="true" ng-model="row.entity.${fieldName}"></span>`;
            } else {
                template +=
                    '<div class="input-group grid-colour">' +
                    `<span ng-model="row.entity.${fieldName}" class="input-group-addon input-group-addon-colour" ng-style="{ 'background-color': row.entity.${fieldName}, 'border-color': row.entity.${fieldName}}"></span>` +
                    `<input type="text" class="form-control" colorpicker colorpicker-position="bottom" colorpicker-fixed-position="true" ng-model="row.entity.${fieldName}"/>` +
                    '</div>';
            }
            template += '</div>';
            return template;
        }

        private deleteButtonTemplate(info: string): string {
            const enabled = info !== 'undefined';
            return (
                '<div class="text-center vertical-center">' +
                '<div class="tooltip-wrapper" bs-enabled="' +
                enabled +
                `" data-container="body" data-delay="300,100" ${this.tooltip(info)}>` +
                '<button type="button" class="btn btn-danger btn-sm" ng-click="grid.api.remeCare.deleteRow(grid, row.entity)" ng-disabled="grid.api.remeCare.deleteDisabled(row.entity)"><i class="glyphicon glyphicon-trash"></i></button>' +
                '   </div>' +
                '   </div>'
            );
        }

        private boolColumnTemplate(fieldName: string, showFalse: boolean, reverse?: boolean): string {
            let cellTemplate =
                '<div class="text-center vertical-center" ng-class="{link: grid.options.navigationEnabled}" ng-click="grid.api.remeCare.navigate(row.entity)">' +
                '<span class="control-label">';
            if (!reverse) {
                cellTemplate +=
                    '<i class="glyphicon" ng-class="{ \'glyphicon-ok green\' : row.entity.' +
                    fieldName +
                    " , 'glyphicon-remove red' : !row.entity." +
                    fieldName +
                    ' && + ' +
                    showFalse +
                    ' }"></i>';
            } else {
                cellTemplate +=
                    '<i class="glyphicon" ng-class="{ \'glyphicon-ok green\' : !row.entity.' +
                    fieldName +
                    " , 'glyphicon-remove red' : row.entity." +
                    fieldName +
                    ' && + ' +
                    showFalse +
                    ' }"></i>';
            }
            cellTemplate += '</span></div>';

            return cellTemplate;
        }

        private boolColumnEditTemplate(fieldName: string): string {
            const cellTemplate = '<input type="checkbox" ng-model="row.entity.' + fieldName + '">';

            return cellTemplate;
        }

        private conditionalBoolColumnEditTemplate(fieldName: string): string {
            const cellTemplate =
                '<input type="checkbox" ng-model="row.entity.' +
                fieldName +
                '" ng-disabled="grid.api.remeCare.editDisabled(row.entity)">';

            return cellTemplate;
        }

        private simpleDropDownColumnEditTemplate(fieldName: string): string {
            const cellTemplate =
                '<div class="col-sm-12">' +
                '<select class="form-control input-sm" ng-model="row.entity.' +
                fieldName +
                '"  ng-options="item for item in col.colDef.editDropdownOptionsArray"></select>' +
                '</div>';

            return cellTemplate;
        }

        private checkBoxColumnTemplate(fieldName: string): string {
            const cellTemplate =
                '<div class="col-sm-12">' +
                ' <input type="checkbox" ng-model="row.entity.' +
                fieldName +
                '" ng-disabled="grid.api.remeCare.editDisabled(row.entity)" />' +
                '</div>';
            return cellTemplate;
        }

        private checkBoxColumnWithFunctionTemplate(fieldName: string): string {
            const cellTemplate =
                '<div class="col-sm-12 text-center">' +
                ' <input type="checkbox" ng-change="grid.api.remeCare.editRow(grid, row.entity)" ng-model="row.entity.' +
                fieldName +
                '" ng-disabled="grid.api.remeCare.editDisabled(row.entity)" />' +
                '</div>';
            return cellTemplate;
        }

        private numberColumnTemplate(fieldName: string): string {
            const cellTemplate =
                '<div class="col-sm-12">' +
                ' <input type="number" class="input-sm" ng-model="row.entity.' +
                fieldName +
                '" ng-disabled="grid.api.remeCare.editDisabled(row.entity)" />' +
                '</div>';
            return cellTemplate;
        }

        private textColumnTemplate(fieldName: string): string {
            const cellTemplate =
                '<div class="col-sm-12">' +
                ' <input type="text" class="input-sm" ng-model="row.entity.' +
                fieldName +
                '" ng-disabled="grid.api.remeCare.editDisabled(row.entity)" />' +
                '</div>';
            return cellTemplate;
        }

        private dropDownColumnEditTemplate(fieldName: string, value: string, label: string): string {
            const cellTemplate =
                '<div class="col-sm-12">' +
                '<select class="form-control input-sm" ng-model="row.entity.' +
                fieldName +
                '"  ng-options="item.' +
                label +
                ' for item in col.colDef.editDropdownOptionsArray track by item.' +
                value +
                '"></select>' +
                '</div>';

            return cellTemplate;
        }

        private defaultCellTemplate(field: string, filter: string, classConditions: string[]): string {
            classConditions.push('link: grid.options.navigationEnabled');
            const conditions = classConditions.join(', ');
            const result = `<div class="ui-grid-cell-contents" ng-click="grid.api.remeCare.navigate(row.entity)" ng-class="{${conditions}}" data-container="body" data-delay="500,100" ${this.tooltipField(
                field,
                filter
            )}>{{COL_FIELD CUSTOM_FILTERS}}{{ col.colDef.extraInfo(row.entity) }}</div>`;
            return result;
        }

        private dateRangeCellTemplate(field1: string, field2: string, filter: string): string {
            if (filter != null) {
                filter = filter.replace(/"/g, "'");
            }
            let result = `<div class="ui-grid-cell-contents" data-container="body" data-delay="500,100"`;
            result += `title="{{ row.entity.${field1} | ${filter} }} - {{ row.entity.${field2} | ${filter} }}`;
            result += `">{{row.entity.${field1} CUSTOM_FILTERS}} <div ng-show='!grid.api.remeCare.areEqual(row.entity, "${field1}", "${field2}")'>{{row.entity.${field2} CUSTOM_FILTERS}}</div></div>`;
            return result;
        }

        private actionOnScopeTemplate(func: string): string {
            return (
                '<div class="ui-grid-cell-contents" ng-class="{link: grid.options.navigationEnabled}" ng-click="grid.api.remeCare.navigate(row.entity)" bs-tooltip data-container="body" data-delay="500,100" data-title="{{ grid.appScope.' +
                func +
                '(row.entity)}}">' +
                '{{ grid.appScope.' +
                func +
                '(row.entity)}}' +
                '</div>'
            );
        }

        private linkActionCellTemplate(field: string, filter: string, func: string): string {
            const result = `<div class="ui-grid-cell-contents" data-container="body" data-delay="500,100" ${this.tooltipField(
                field,
                filter
            )}><span ng-click="grid.appScope.${func}(row.entity)" class="link">{{COL_FIELD CUSTOM_FILTERS}}</span></div>`;
            return result;
        }

        private linkCellTemplate(field: string, filter: string, link: string): string {
            const result = `<div class="ui-grid-cell-contents" data-container="body" data-delay="500,100" ${this.tooltipField(
                field,
                filter
            )}><a data-ng-href="${link}" class="">{{COL_FIELD CUSTOM_FILTERS}}</a></div>`;
            return result;
        }

        private upDownButtonTemplate(field: string): string {
            return (
                '<div class="text-center vertical-center">' +
                '<div class="btn-toolbar text-center">' +
                '<button type="button" ng-show="rowRenderIndex > 0" ng-disabled="grid.api.remeCare.upDownDisabled(row.entity)" ng-click="grid.api.remeCare.moveUp(grid, row.entity, \'' +
                field +
                '\')" class="btn btn-primary btn-xs">' +
                '<i class="fa fa-chevron-up"></i>' +
                '</button>' +
                '<button type="button" ng-show="rowRenderIndex < grid.rows.length - 1" ng-disabled="grid.api.remeCare.upDownDisabled(row.entity)" ng-click="grid.api.remeCare.moveDown(grid, row.entity, \'' +
                field +
                '\')" class="btn btn-primary btn-xs">' +
                '<i class="fa fa-chevron-down"></i>' +
                '</button>' +
                '</div>' +
                '</div>'
            );
        }

        private rowTemplate(classConditions: string[]): string {
            const conditions = classConditions.join(', ');
            return `<div ng-class="{${conditions}}"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }" ui-grid-cell></div></div>`;
        }

        private headerCellTemplateWithTooltip(): string {
            return `<div role="columnheader" ng-class="{ 'sortable': sortable }" ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'"
                    bs-tooltip data-container="body" data-delay="500,100" data-title="{{ col.displayName CUSTOM_FILTERS }}"
                   aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}" >
            <div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP" >
                <span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + '-header-text'">{{ col.displayName CUSTOM_FILTERS }} </span>

                <span ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'" ui-grid-visible="col.sort.direction" aria-label="{{getSortDirectionAriaLabel()}}" >
                    <i ng- class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }"
                    title = "{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}"
                    aria-hidden="true" ></i>
                    <sub ui-grid-visible="isSortPriorityVisible()" class="ui-grid-sort-priority-number">{{col.sort.priority + 1 }}</sub>
                </span>
            </div>

            <div role ="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + '-menu-button'" class="ui-grid-column-menu-button" ng-if="grid.options.enableColumnMenus && !col.isRowHeader && col.colDef.enableColumnMenu !== false"
                ng-click="toggleMenu($event)" ng-class="{'ui-grid-column-menu-button-last-col': isLastCol}" ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" aria-haspopup="true">
                    <i class="ui-grid-icon-angle-down" aria-hidden="true">  &nbsp; </i>  
            </div>
            <div ui-grid-filter> </div>
        </div>`;
        }

        private createColumn(
            field: string,
            displayName: string,
            options?: any,
            classConditions?: string[],
            extraInfo?: (object: T) => string
        ) {
            const column: any = options == null ? {} : options;
            classConditions = classConditions || [];
            column.field = field;
            column.displayName = displayName;
            column.headerCellFilter = 'translate';
            column.cellTemplate = this.defaultCellTemplate(field, column.cellFilter, classConditions);
            column.extraInfo = extraInfo;
            return column;
        }

        private addEditButtonFunctionColumn(buttonIcon?: string): void {
            buttonIcon = buttonIcon == null ? 'pencil' : buttonIcon;
            const editButtonWithFunctionTemplate = this.editButtonWithFunctionTemplate.replace(
                '$$buttonIcon$$',
                buttonIcon
            );
            const column = {
                name: 'edit',
                displayName: '',
                cellTemplate: editButtonWithFunctionTemplate,
                width: 80,
                enableSorting: false,
            };
            this.columnDefs.push(column);
        }

        // Don't use index, but use entity instead
        // https://stackoverflow.com/questions/27122414/angular-js-ui-grid-delete-row
        private getRowIndex(grid: any, entity: T): number {
            return grid.options.data.indexOf(entity);
        }

        private configureApi(buildGrid: Grid<T>): void {
            buildGrid.gridOptions.onRegisterApi = (gridApi) => {
                buildGrid.gridApi = gridApi;
                this.gridApi = gridApi;
                this.gridApi.core.on.sortChanged(buildGrid.scope, (grid, sortColumns) => {
                    buildGrid.scope.pagingOptions.currentPage = 1;
                    if (sortColumns.length === 0) {
                        buildGrid.sortOptions.field = null;
                        buildGrid.sortOptions.direction = null;
                    } else {
                        buildGrid.sortOptions.field = sortColumns[0].field;
                        buildGrid.sortOptions.direction =
                            sortColumns[0].sort.direction === this.uiGridConstants.ASC ? 'asc' : 'desc';
                    }
                    if (!this.searchFunction) {
                        return;
                    }
                    buildGrid.search();
                });
                this.gridApi.registerMethod('remeCare', 'getRowStyle', (grid: any, entity: T) => {
                    const rowIndex = this.getRowIndex(grid, entity);
                    const cells = $(`#${this.gridId} .ui-grid-row:nth-child(${rowIndex + 1})`)
                        .find('.ui-grid-cell-contents')
                        .get();
                    const height = _(cells)
                        .chain()
                        .map((c: Element) => c.scrollHeight)
                        .max()
                        .value();
                    return { height: `${height}px` };
                });
                this.gridApi.registerMethod('remeCare', 'deleteRow', (grid: any, entity: T) => {
                    const index = this.getRowIndex(grid, entity);
                    const data = grid.options.data;
                    if (this.confirmDelete) {
                        this.confirmDelete(entity).then((r) => {
                            if (r) {
                                data.splice(index, 1)[0];
                                if (this.onDelete) {
                                    this.onDelete(entity);
                                }
                            }
                        });
                    } else {
                        data.splice(index, 1)[0];
                        if (this.onDelete) {
                            this.onDelete(entity);
                        }
                    }
                });
                this.gridApi.registerMethod('remeCare', 'selectEntity', (entity: T) => {
                    this.select(entity);
                });
                this.gridApi.registerMethod('remeCare', 'handleClick', (data: any) => {
                    this.handle(data);
                });
                this.gridApi.registerMethod('remeCare', 'editRow', (grid: any, entity: T) => {
                    const index = this.getRowIndex(grid, entity);
                    const data = grid.options.data;
                    if (this.editFunction) {
                        const newEntity = this.editFunction(entity);
                        data[index] = newEntity;
                    } else {
                        const promise = this.editFunctionWithPromise(entity);
                        promise.then((result) => {
                            data[index] = result;
                        });
                    }
                });
                this.gridApi.registerMethod('remeCare', 'editDisabled', (entity: T) => {
                    if (!this.editDisabledFunction) {
                        return false;
                    }
                    return this.editDisabledFunction(entity);
                });
                this.gridApi.registerMethod('remeCare', 'showButton', (entity: T) => {
                    if (!this.showButtonFunction) {
                        return false;
                    }
                    return this.showButtonFunction(entity);
                });
                this.gridApi.registerMethod('remeCare', 'showDisabled', (entity: T) => {
                    if (!this.showDisabledFunction) {
                        return false;
                    }
                    return this.showDisabledFunction(entity);
                });
                this.gridApi.registerMethod('remeCare', 'deleteDisabled', (entity: T) => {
                    if (!this.deleteDisabledFunction) {
                        return false;
                    }
                    return this.deleteDisabledFunction(entity);
                });
                this.gridApi.registerMethod('remeCare', 'upDownDisabled', (entity: T) => {
                    if (!this.upDownDisabledFunction) {
                        return false;
                    }
                    return this.upDownDisabledFunction(entity);
                });
                this.gridApi.registerMethod('remeCare', 'showDetail', (entity: T) => {
                    if (!this.showDetail) {
                        return false;
                    }
                    return this.showDetail(entity);
                });
                this.gridApi.registerMethod('remeCare', 'moveUp', (grid: any, entity: T, field: string) => {
                    const index = this.getRowIndex(grid, entity);
                    const gridData = grid.options.data as T[];
                    const data = angular.copy(gridData).reverse();
                    const next = _.find(data, (item) => item[field] < entity[field]);
                    if (next != null) {
                        const temp = next[field];
                        next[field] = entity[field];
                        entity[field] = temp;
                        gridData.splice(index - 1, 2);
                        gridData.splice(index - 1, 0, entity, next);
                        if (this.onRowsSwitched) {
                            this.onRowsSwitched(entity, next);
                        }
                    }
                });
                this.gridApi.registerMethod('remeCare', 'moveDown', (grid: any, entity: T, field: string) => {
                    const index = this.getRowIndex(grid, entity);
                    const data = grid.options.data as T[];
                    const next = _.find(data, (item) => item[field] > entity[field]);
                    if (next != null) {
                        const temp = next[field];
                        next[field] = entity[field];
                        entity[field] = temp;
                        data.splice(index, 1);
                        data.splice(index + 1, 0, entity);
                        if (this.onRowsSwitched) {
                            this.onRowsSwitched(entity, next);
                        }
                    }
                });
                this.gridApi.registerMethod(
                    'remeCare',
                    'isTodayBetweenDates',
                    (grid: any, entity: T, dateField: string) => {
                        const rowIndex = this.getRowIndex(grid, entity);
                        const data = grid.options.data;
                        if (rowIndex <= 0) {
                            return false;
                        }
                        const previousRow = data[rowIndex - 1];
                        const currentRow = data[rowIndex];
                        if (previousRow == null || currentRow == null) {
                            return false;
                        }
                        const previousDate = DateHelper.serverDateStringToDate(previousRow[dateField]);
                        const currentDate = DateHelper.serverDateStringToDate(currentRow[dateField]);
                        const today = DateHelper.today();
                        if (previousDate == null || currentDate == null) {
                            return false;
                        }
                        if (
                            moment(previousDate).isAfter(today) &&
                            (moment(currentDate).isSame(today) || moment(currentDate).isBefore(today))
                        ) {
                            return true;
                        }
                        return false;
                    }
                );
                this.gridApi.registerMethod('remeCare', 'navigate', (entity: T) => {
                    if (!this.navigation && !this.navigationAction) {
                        return;
                    }

                    if (this.editDisabledFunction && this.editDisabledFunction(entity)) {
                        return;
                    }
                    if (this.showDisabledFunction && this.showDisabledFunction(entity)) {
                        return;
                    }

                    if (this.navigationAction) {
                        this.navigationAction(entity);
                    }

                    if (this.navigation) {
                        const parameters = {};
                        for (const key in this.navigation.parameters) {
                            if (this.navigation.parameters.hasOwnProperty(key)) {
                                const accessors = (this.navigation.parameters[key] as string).split('.');
                                let obj = entity;
                                for (const accessor of accessors) {
                                    obj = obj[accessor];
                                }
                                parameters[key] = obj;
                            }
                        }

                        for (const key in this.navigation.nonEntityParams) {
                            if (this.navigation.nonEntityParams.hasOwnProperty(key)) {
                                const accessors = (this.navigation.nonEntityParams[key] as string).split('.');
                                let obj = gridApi.grid.appScope;
                                while (!obj.hasOwnProperty(accessors[0])) {
                                    obj = obj.$parent;
                                }
                                for (const accessor of accessors) {
                                    obj = obj[accessor];
                                }
                                parameters[key] = obj;
                            }
                        }
                        this.$state.go(this.navigation.state, parameters);
                    }
                });
                this.gridApi.registerMethod('remeCare', 'areEqual', (entity: T, field1: string, field2: string) => {
                    if (entity[field1] !== entity[field2]) {
                        return false;
                    }
                    return true;
                });
            };
        }
    }

    export interface ColumnConfiguration<T> {
        iconName: string;
        action(T): JQueryPromise<T>;
    }

    export class GridBuilderFactory {
        constructor(
            private uiGridConstants: uiGrid.IUiGridConstants,
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $timeout: ng.ITimeoutService,
            private readonly $window: ng.IWindowService,
            private readonly loadMeasuringSvc: Service.LoadMeasuringService,
            private readonly $state: ng.ui.IStateService,
            private readonly urlBinderFactory: Factory.UrlBinderFactory
        ) {}

        public createGridBuilder<T>(searchFunction?: SearchFunction<T>): SearchGridBuilder<T> {
            const factory = this.urlBinderFactory;
            const convertedSearchFunction = this.convert(searchFunction);

            return new SearchGridBuilder<T>(
                this.uiGridConstants,
                convertedSearchFunction,
                this.$rootScope.$new(false) as IGridScope,
                this.$timeout,
                factory,
                this.$window,
                this.loadMeasuringSvc,
                this.$state
            );
        }

        private convert<T>(searchFunction?: SearchFunction<T>): PromiseSearchFunction<T> {
            if (!searchFunction) {
                return null;
            }
            const promiseSearchFunction = (
                page: number,
                pageSize: number,
                sortField: string,
                sortDirection: string,
                criteria: any,
                count: boolean,
                nextPage: boolean
            ) => {
                return new Promise<Contract.ISearchResult<T>>((resolve, reject) => {
                    searchFunction(page, pageSize, sortField, sortDirection, criteria, count, nextPage).then(
                        (searchResult) => {
                            resolve(searchResult);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                });
            };
            return promiseSearchFunction;
        }
    }

    remeCareSharedModule.service('gridBuilderSvc', GridBuilderFactory);
}
