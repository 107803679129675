var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var RuleThresholdAttribute;
                (function (RuleThresholdAttribute) {
                    RuleThresholdAttribute[RuleThresholdAttribute["Id"] = 1] = "Id";
                    RuleThresholdAttribute[RuleThresholdAttribute["Value"] = 2] = "Value";
                })(RuleThresholdAttribute = Code.RuleThresholdAttribute || (Code.RuleThresholdAttribute = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
