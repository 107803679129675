namespace RemeCare.Shared.Contract.Code {
    export enum CareRequestUIControlType {
        PatientName = 1,
        PatientNationalNumber = 2,
        PatientBirthDate = 3,
        PatientCommunicationLanguage = 4,
        PatientGender = 5,
        PatientAddress = 6,
        PatientCountry = 7,
        PatientNationality = 8,
        PatientTelephone = 9,
        PatientEmail = 10,
        PatientMaritalStatus = 11,
        PatientTechnologicalAffinity = 12,
        PatientEducationLevel = 13,
        PatientCodeStudy = 14,
        Contact = 15,
        ContactPersonName = 16,
        ContactPersonGender = 17,
        ContactPersonTypeSupport = 18,
        ContactPersonAddress = 19,
        ContactPersonCountry = 20,
        ContactPersonTelephoneMobile = 21,
        ContactPersonEmail = 22,
        ContactPersonNonProfHCProvider = 23,
        CareRequestorHCProfessional = 24,
        CareRequestorName = 25,
        CareRequestorTelephoneMobile = 26,
        CareRequestorRIZIV = 27,
        CareRequestorEmail = 28,
        CareRequestorContactPerson = 29,
        CareRequestorContactName = 30,
        CareRequestorContactTelephoneMobile = 31,
        CareRequestorContactFunction = 32,
        CareRequestorSpeciality = 33,
        CareRequestorHCOrganisation = 34,
        CareRequestorIntermediary = 35,
        CareRequestRole = 36,
        CareRequestRoleName = 37,
        CareRequestRoleTelephoneMobile = 38,
        CareRequestRoleRIZIV = 39,
        CareRequestRoleEmail = 40,
        CareRequestRoleAddress = 41,
        QuantitativeAnamnesis = 42,
        String = 43,
        TherapyOption = 44,
        Date = 45,
        RequestDocument = 46,
        Comorbidities = 47,
        TreatedDiseases = 48,
        Hospitalisation = 49,
        HospitalisationStartDate = 50,
        HospitalisationEndDate = 51,
        HospitalisationDepartment = 52,
        HospitalisationReason = 53,
        HospitalisationCarePlanRelated = 54,
        Consultation = 55,
        ConsultationStartDate = 56,
        ConsultationEndDate = 57,
        ConsultationCareProviderType = 58,
        ConsultationReason = 59,
        ConsultationCarePlanRelated = 60,
        MedicationSchema = 61,
        QualitativeAnamnesis = 62,
        MedicationSchemaTherapyAction = 63,
        CareRequestorContactEmail = 64,
        PatientMobile = 65,
        Signature = 66,
    }
}
