var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var CodeSystem = /** @class */ (function () {
                    function CodeSystem(serverObject) {
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.name = serverObject.Name;
                            this.uri = serverObject.Uri;
                        }
                    }
                    return CodeSystem;
                }());
                Model.CodeSystem = CodeSystem;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
