namespace RemeCare.Shared.Framework.Model {
    import Guid = Shared.Contract.Guid;
    import IEnumTranslation = Shared.Contract.IEnumTranslation;
    import RelatedStructuralTherapyActionParameter = Shared.Contract.Write.IRelatedStructuralTherapyActionParameter;
    import RelatedStructuralTherapyAction = Shared.Contract.Read.IRelatedStructuralTherapyAction;
    import Duration = Shared.Contract.IDuration;

    export class TherapyActionPartContextSetting {
        public TherapyActionPartContextSettingId: Guid;
        public IsMandatory: boolean;
        public GuidanceCode: string;
        public GuidanceEnabled: boolean;
        public IsLeftOutForRemeCoach: boolean;
        public SyncToFhir: boolean;
        public CodeSystemOverrides: Shared.Contract.Read.ICodeSystemOverride[];
        public RemeCoachDefaultValue?: number;
        public TherapyActionPart: Shared.Contract.Read.ITherapyActionPart;
        public HasLocation: boolean;
        public HasExecutor: boolean;
        public CodeSetType: Shared.Contract.Read.ICodeSetTypeNew;
        public DateType: IEnumTranslation;
        public HasTime: boolean;
        public IsWindow: boolean;
        public Name: string;
        public NumberOfAttempts: number;
        public IsTherapyMediaLibItem: boolean;
        public SimpleInformationObject: Shared.Contract.Read.IInformationObject;
        public AnswerConditions: AnswerCondition[];
        public CodeSetVersion: Shared.Contract.Read.ICodeSetTypeVersion;
        public ShowObservableEntity: boolean;
        public ShowCharacteristic: boolean;
        public ExternalContextReference: string;
        public ExternalParameterReference: string;
        public Decimals: number;
        public TherapyActionPartInputDevice: Shared.Contract.Read.ITherapyActionPartInputDeviceReference;
        public IsTelemonitoringEnabled: boolean;
        public IntendedValue: IEnumTranslation;
        public HasStandardPhrases: boolean;
        public AllowsAllFileTypes: boolean;
        public HasReferenceDate: boolean;
        public IsFollowUpDocument: boolean;
        public IsExternalDocument: boolean;
        public RequestInfoFileTypes: Shared.Contract.Read.IRequestInfoFileType[];
        public DefaultDelayDuration: Duration;
        public DefaultFromTime: string;
        public DefaultUntilTime: string;
        public IsBasedOnCarePlanStartDate: boolean;
        public LocationSettings: Shared.Contract.Read.ILocationSetting[];
        public IsEditable: boolean;
        public CanPersonaliseTelemonitoringDeactivation: boolean;
        public CanPersonaliseToHidePart: boolean;
        public ChildRelatedStructuralTherapyAction: Shared.Contract.Read.IRelatedStructuralTherapyAction;
        public DocumentCategories: Guid[];
        public IsCrossProcessInstance: boolean;
        public LinkedThresholdId: Guid;
        public MinimumValue?: number;
        public MaximumValue?: number;
        public StructuralTherapyActionToPlanId?: Guid;
        public IsMandatoryOverrides: TherapyActionPartContextSettingMandatoryOverride[];
        public ExternalReference: string;
        public DoNotTreatRegistrationAsRemark: boolean;

        constructor(serverObject?: Shared.Contract.Read.ITherapyActionPartContextSetting) {
            if (serverObject != null) {
                const groupedAnswerConditions = _.groupBy(
                    serverObject.AnswerConditions,
                    (ac) => ac.AnswerCodeSetItem.Id
                );
                const answerConditions = _.map(groupedAnswerConditions, (group) => {
                    const ac = new AnswerCondition();
                    ac.AnswerCodeSetItem = group[0].AnswerCodeSetItem;
                    ac.GoToIds = _.map(group, (member) => member.GoToId);
                    return ac;
                });
                const mandatoryOverrides: TherapyActionPartContextSettingMandatoryOverride[] = [];
                if (serverObject.IsMandatoryOverrides !== undefined) {
                    serverObject.IsMandatoryOverrides.forEach((mor) => {
                        // if first override for this codesetitem create it
                        let mandatoryOverride = mandatoryOverrides.filter(
                            (f) => f.CareActCodeSetItemId === mor.CareActCodeSetItemId
                        )[0];
                        if (mandatoryOverride === undefined) {
                            mandatoryOverride = new TherapyActionPartContextSettingMandatoryOverride();
                            mandatoryOverride.CareActCodeSetItemId = mor.CareActCodeSetItemId;
                            mandatoryOverride.MandatoryTherapyActionParts = [];
                            mandatoryOverrides.push(mandatoryOverride);
                        }
                        // add override to list
                        mandatoryOverride.MandatoryTherapyActionParts.push(mor.MandatoryTherapyActionPartId);
                    });
                }
                this.TherapyActionPartContextSettingId = serverObject.TherapyActionPartContextSettingId;
                this.IsMandatory = serverObject.IsMandatory;
                this.SyncToFhir = serverObject.SyncToFhir;
                this.CodeSystemOverrides = serverObject.CodeSystemOverrides;
                this.GuidanceCode = serverObject.GuidanceCode;
                this.GuidanceEnabled = serverObject.GuidanceEnabled;
                this.IsLeftOutForRemeCoach = serverObject.IsLeftOutForRemeCoach;
                this.RemeCoachDefaultValue = serverObject.RemeCoachDefaultValue;
                this.TherapyActionPart = serverObject.TherapyActionPart;
                this.HasLocation = serverObject.HasLocation;
                this.HasExecutor = serverObject.HasExecutor;
                this.CodeSetType = serverObject.CodeSetType;
                this.DateType = serverObject.DateType;
                this.HasTime = serverObject.HasTime;
                this.IsWindow = serverObject.IsWindow;
                this.Name = serverObject.Name;
                this.NumberOfAttempts = serverObject.NumberOfAttempts;
                this.IsTherapyMediaLibItem = serverObject.IsTherapyMediaLibItem;
                this.SimpleInformationObject = serverObject.SimpleInformationObject;
                this.AnswerConditions = answerConditions;
                this.ShowObservableEntity = serverObject.ShowObservableEntity;
                this.ShowCharacteristic = serverObject.ShowCharacteristic;
                this.ExternalContextReference = serverObject.ExternalContextReference;
                this.ExternalParameterReference = serverObject.ExternalParameterReference;
                this.Decimals = serverObject.Decimals;
                this.TherapyActionPartInputDevice = {
                    Id: serverObject.InputId,
                    Device: {
                        Id: null,
                        Device: null,
                    },
                    Characteristic: {
                        Id: null,
                        Characteristic: null,
                    },
                };
                this.IsTelemonitoringEnabled = serverObject.IsTelemonitoringEnabled;
                this.IntendedValue = serverObject.IntendedValue;
                this.HasStandardPhrases = serverObject.HasStandardPhrases;
                this.AllowsAllFileTypes = serverObject.AllowsAllFileTypes;
                this.HasReferenceDate = serverObject.HasReferenceDate;
                this.IsFollowUpDocument = serverObject.IsFollowUpDocument;
                this.IsExternalDocument = serverObject.IsExternalDocument;
                this.RequestInfoFileTypes = serverObject.RequestInfoFileTypes;
                this.DefaultDelayDuration = serverObject.DefaultDelayDuration;
                this.DefaultFromTime = serverObject.DefaultFromTime;
                this.DefaultUntilTime = serverObject.DefaultUntilTime;
                this.IsBasedOnCarePlanStartDate = serverObject.IsBasedOnCarePlanStartDate;
                this.LocationSettings = serverObject.LocationSettings;
                this.IsEditable = serverObject.IsEditable;
                this.CanPersonaliseTelemonitoringDeactivation = serverObject.CanPersonaliseTelemonitoringDeactivation;
                this.CanPersonaliseToHidePart = serverObject.CanPersonaliseToHidePart;
                this.ChildRelatedStructuralTherapyAction = serverObject.ChildRelatedStructuralTherapyAction;
                this.DocumentCategories = serverObject.DocumentCategories;
                this.IsCrossProcessInstance = serverObject.IsCrossProcessInstance;
                this.LinkedThresholdId = serverObject.LinkedThresholdId;
                this.MinimumValue = serverObject.MinimumValue;
                this.MaximumValue = serverObject.MaximumValue;
                this.StructuralTherapyActionToPlanId = serverObject.StructuralTherapyActionToPlanId;
                this.IsMandatoryOverrides = mandatoryOverrides;
                this.ExternalReference = serverObject.ExternalReference;
                this.DoNotTreatRegistrationAsRemark = !serverObject.TreatRegistrationAsRemark;
            }
        }

        public expandAnswerConditions(codeSetVersion: Shared.Contract.Read.ICodeSetTypeVersion): void {
            this.AnswerConditions = _(codeSetVersion.CodeSetTypeCodeSetItems).map((item) => {
                const existing = _.find(this.AnswerConditions, (x) => x.AnswerCodeSetItem.Id === item.CodeSetItemId);
                if (existing) {
                    return existing;
                } else {
                    const result = new AnswerCondition();
                    result.AnswerCodeSetItem = {
                        Id: item.CodeSetItemId,
                        Text: item.Name,
                    };
                    return result;
                }
            });

            this.CodeSetVersion = codeSetVersion;
        }

        public getDropdownText(): string {
            let text: string;
            const tap = this.TherapyActionPart;
            switch (tap.ActionTypeCode) {
                case Contract.Code.ActionType.QualitativeObservation:
                    text = (tap.valueOf() as Shared.Contract.Read.IQualitativeObservation).ObservableEntity;
                    break;
                case Contract.Code.ActionType.QuantitativeObservation:
                    text = tap.ActionTypeText + ': ' + tap.ProcessParameter;
                    break;
                case Contract.Code.ActionType.Date:
                    text = tap.ActionTypeText + ': ' + tap.ProcessParameter;
                    break;
                default:
                    text = tap.ActionTypeText;
                    break;
            }
            return tap.Sequence + '. ' + text;
        }

        public toServerWrite(
            isRemeCoachEnabled = false
        ): Shared.Contract.Write.ITherapyActionPartContextSettingParameter {
            let simpleInformationObject = null;
            if (!this.IsTherapyMediaLibItem && this.SimpleInformationObject) {
                simpleInformationObject = {
                    InformationObjectId: this.SimpleInformationObject.InformationObjectId,
                    Name: this.SimpleInformationObject.Name,
                    Description: this.SimpleInformationObject.Description,
                    TypeId: this.SimpleInformationObject.Type.Id,
                    Url: this.SimpleInformationObject.Url,
                    Copy: this.SimpleInformationObject.Copy,
                };
                if (
                    this.SimpleInformationObject.Type.Id === Shared.Contract.Code.InformationObjectType.LinkToFile &&
                    this.SimpleInformationObject.Documents.length > 0
                ) {
                    simpleInformationObject.InformationObjectTranslationId = this.SimpleInformationObject.Documents[0].Id;
                    simpleInformationObject.Url = this.SimpleInformationObject.Documents[0].Url;
                }
            }
            const childRelated = this.mapRelated(this.ChildRelatedStructuralTherapyAction);
            const answerconditions = new Array<Shared.Contract.Write.IAnswerCondition>();
            _.map(this.AnswerConditions, (ac) => {
                _.map(ac.GoToIds, (goToId) => {
                    answerconditions.push({
                        AnswerCodeSetItemId: ac.AnswerCodeSetItem.Id,
                        GoToActionPartId: goToId,
                    } as Shared.Contract.Write.IAnswerCondition);
                });
            });

            if (!this.IsTelemonitoringEnabled && !this.IsExternalDocument) {
                this.ExternalContextReference = null;
                this.ExternalParameterReference = null;
            }

            if (!isRemeCoachEnabled || !this.IsTelemonitoringEnabled) {
                this.TherapyActionPartInputDevice = null;
            }

            const therapyActionPartContextSettingMandatoryOverridesToWrite = new Array<
                Shared.Contract.Write.ITherapyActionPartContextSettingMandatoryOverride
            >();

            if (this.IsMandatoryOverrides !== undefined && this.IsMandatoryOverrides !== null) {
                for (const tapcsmo of this.IsMandatoryOverrides) {
                    if (
                        tapcsmo.MandatoryTherapyActionParts !== undefined &&
                        tapcsmo.MandatoryTherapyActionParts !== null
                    ) {
                        for (const tapcsItem of tapcsmo.MandatoryTherapyActionParts) {
                            const tapcsmotw = new Shared.Contract.Write.ITherapyActionPartContextSettingMandatoryOverride();
                            tapcsmotw.CareActCodeSetItemId = tapcsmo.CareActCodeSetItemId;
                            tapcsmotw.MandatoryTherapyActionPartId = tapcsItem;
                            therapyActionPartContextSettingMandatoryOverridesToWrite.push(tapcsmotw);
                        }
                    }
                }
            }

            const write: Shared.Contract.Write.ITherapyActionPartContextSettingParameter = {
                TherapyActionPartContextSettingId: this.TherapyActionPartContextSettingId,
                IsMandatory: this.IsMandatory,
                SyncToFhir: this.SyncToFhir,
                CodeSystemOverrides: _(this.CodeSystemOverrides).map((cso) => ({
                    code: cso.Code,
                    codeSystemId: cso.CodeSystem.Id,
                    codeSetItemId: cso.CodeSetItemId,
                })),
                GuidanceCode: this.GuidanceCode,
                GuidanceEnabled: this.GuidanceEnabled,
                IsLeftOutForRemeCoach: this.IsLeftOutForRemeCoach,
                RemeCoachDefaultValue: this.RemeCoachDefaultValue,
                TherapyActionPartId: this.TherapyActionPart.TherapyActionPartId,
                Decimals: this.Decimals,
                InputId: this.TherapyActionPartInputDevice ? this.TherapyActionPartInputDevice.Id : null,
                IntendedValueId: this.IntendedValue != null ? this.IntendedValue.Id : null,
                NumberOfAttempts: this.NumberOfAttempts,
                IsTelemonitoringEnabled: this.IsTelemonitoringEnabled,
                HasLocation: this.HasLocation,
                HasExecutor: this.HasExecutor,
                CodeSetTypeId: this.CodeSetType != null ? this.CodeSetType.Id : null,
                Name: this.Name,
                DateTypeId: this.DateType != null ? this.DateType.Id : null,
                HasTime: this.HasTime,
                IsWindow: this.IsWindow,
                HasStandardPhrases: this.HasStandardPhrases,
                AllowsAllFileTypes: this.AllowsAllFileTypes,
                HasReferenceDate: this.HasReferenceDate,
                IsFollowUpDocument: this.IsFollowUpDocument,
                IsExternalDocument: this.IsExternalDocument,
                IsBasedOnCarePlanStartDate: this.IsBasedOnCarePlanStartDate,
                IsEditable: this.IsEditable,
                IsTherapyMediaLibItem: this.IsTherapyMediaLibItem,
                SimpleInformationObject: simpleInformationObject,
                DefaultDelayDuration:
                    this.DefaultDelayDuration && this.DefaultDelayDuration.Unit
                        ? {
                              Unit: this.DefaultDelayDuration.Unit,
                              Quantity: this.DefaultDelayDuration.Quantity,
                          }
                        : null,
                DefaultFromTime: this.DefaultFromTime,
                DefaultUntilTime: this.DefaultUntilTime,
                AnswerConditions: answerconditions,
                ShowObservableEntity: this.ShowObservableEntity,
                ShowCharacteristic: this.ShowCharacteristic,
                ExternalContextReference: this.ExternalContextReference,
                ExternalParameterReference: this.ExternalParameterReference,
                RequestInfoFileTypes: _(this.RequestInfoFileTypes).map((x) => {
                    return {
                        IsActive: x.IsActive,
                        FileTypeId: x.FileType.Id,
                    };
                }),
                LocationSettings: _(this.LocationSettings).map((x) => {
                    return {
                        LocationSettingId: x.LocationSettingId,
                        AddressTypePatientId: x.ContactPointUsageType != null ? x.ContactPointUsageType.Id : null,
                        Duration:
                            x.Duration != null && x.Duration.Unit != null
                                ? {
                                      Unit: x.Duration.Unit,
                                      Quantity: x.Duration.Quantity,
                                  }
                                : null,
                        Sequence: x.Sequence,
                        UsesHCOrgAddress: x.UsesHCOrgAddress,
                    };
                }),
                CanPersonaliseTelemonitoringDeactivation: this.CanPersonaliseTelemonitoringDeactivation,
                CanPersonaliseToHidePart: this.CanPersonaliseToHidePart,
                ChildRelatedStructuralTherapyAction: childRelated,
                DocumentCategories: this.DocumentCategories,
                IsCrossProcessInstance: this.IsCrossProcessInstance,
                LinkedThresholdId: this.LinkedThresholdId,
                MinimumValue: this.MinimumValue,
                MaximumValue: this.MaximumValue,
                StructuralTherapyActionToPlanId: this.StructuralTherapyActionToPlanId,
                IsMandatoryOverrides: therapyActionPartContextSettingMandatoryOverridesToWrite,
                ExternalReference: this.ExternalReference,
                TreatRegistrationAsRemark: !this.DoNotTreatRegistrationAsRemark,
            };
            return write;
        }

        private mapRelated(related: RelatedStructuralTherapyAction): RelatedStructuralTherapyActionParameter {
            if (related == null || related.ChildStructuralTherapyAction == null) {
                return null;
            }
            const rel: RelatedStructuralTherapyActionParameter = {
                ChildStructuralTherapyActionId: related.ChildStructuralTherapyAction.Id,
                TherapyActionRelationTypeId: related.TherapyActionRelationType.Id,
                Delay: undefined,
            };

            if (related.Delay) {
                rel.Delay = {
                    Unit: related.Delay.Unit,
                    Quantity: related.Delay.Quantity,
                };
            }

            return rel;
        }
    }

    export class TherapyActionPartContextSettingMandatoryOverride {
        public CareActCodeSetItemId: Guid;
        public Name: string;
        public MandatoryTherapyActionParts: Guid[];
    }
}
