var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var SearchProductCtrl = /** @class */ (function (_super) {
                __extends(SearchProductCtrl, _super);
                function SearchProductCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, medicationSearchSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.uiGridConstants = uiGridConstants;
                    _this.medicationSearchSvc = medicationSearchSvc;
                    _this.$scope.medications = [];
                    _this.$scope.clearSearchParams = function () { return _this.clearSearchParams(); };
                    _this.$scope.toggleCriteria = function () { return _this.toggleCriteria(); };
                    _this.$scope.search = function () { return _this.search(); };
                    _this.buildGrid();
                    _this.buildSelectedGrid();
                    _this.$scope.searchMedicationGrid.sortOptions = {
                        field: 'MedicationName',
                        direction: uiGridConstants.ASC,
                    };
                    _this.$scope.searchMedicationGrid.searchCriteria = {};
                    _this.$scope.select = function () { return _this.select(); };
                    return _this;
                }
                SearchProductCtrl.prototype.$onInit = function () {
                    this.$scope.criteriaCollapsed = false;
                };
                SearchProductCtrl.prototype.clearSearchParams = function () {
                    this.$scope.searchMedicationGrid.searchCriteria = {};
                };
                SearchProductCtrl.prototype.toggleCriteria = function () {
                    this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
                };
                SearchProductCtrl.prototype.search = function () {
                    this.$scope.searchMedicationGrid.pagingOptions.currentPage = 1;
                    this.$scope.searchMedicationGrid.search();
                };
                SearchProductCtrl.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                    return __awaiter(this, void 0, void 0, function () {
                        var query, data, error_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.$scope.isBusy = true;
                                    query = {
                                        page: page,
                                        pageSize: pageSize,
                                        medicationName: criteria.medicationName,
                                        activeSubstance: criteria.activeSubstance,
                                        productClassIds: [],
                                        sortField: sortField,
                                        sortOrder: sortDirection,
                                        isActive: true,
                                    };
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, this.medicationSearchSvc.searchMedicationsAsync(query)];
                                case 2:
                                    data = _a.sent();
                                    this.$scope.totalItems = data.Total;
                                    this.$scope.isBusy = false;
                                    this.$scope.criteriaCollapsed = true;
                                    return [2 /*return*/, data];
                                case 3:
                                    error_1 = _a.sent();
                                    this.errorCallback(error_1);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                };
                SearchProductCtrl.prototype.buildGrid = function () {
                    var _this = this;
                    this.$scope.searchMedicationGrid = this.gridBuilderSvc
                        .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) { return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria); })
                        .addColumn('MedicationName', 'General.MedicationName')
                        .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                        .addColumn('GalenicForm', 'General.GalenicForm')
                        .addColumn('MedicationTypes', 'General.MedicationType', {
                        cellFilter: 'delimitedDisplay',
                        enableSorting: false,
                    })
                        .addSelectButtonColumn(function (selected) { return _this.resultSelected(selected); })
                        .build();
                };
                SearchProductCtrl.prototype.buildSelectedGrid = function () {
                    var _this = this;
                    this.$scope.selectedMedicationGrid = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('MedicationName', 'General.MedicationName')
                        .addColumn('ActiveSubstance', 'General.ActiveSubstance')
                        .addColumn('GalenicForm', 'General.GalenicForm')
                        .addColumn('MedicationTypes', 'General.MedicationType', {
                        cellFilter: 'delimitedDisplay',
                        enableSorting: false,
                    })
                        .addDeleteButtonColumn(function (selected) { return _this.resultRemoved(selected); })
                        .setExternalSorting(false)
                        .build();
                    this.$scope.selectedMedicationGrid.setData(this.$scope.medications);
                };
                SearchProductCtrl.prototype.resultSelected = function (result) {
                    if (!_(this.$scope.medications).any(function (m) { return m.ProductId === result.ProductId; })) {
                        this.$scope.medications.push(result);
                    }
                };
                SearchProductCtrl.prototype.resultRemoved = function (result) {
                    var index = this.$scope.medications.indexOf(result);
                    this.$scope.medications.splice(index, 1);
                };
                SearchProductCtrl.prototype.select = function () {
                    this.$scope.$close(this.$scope.medications);
                };
                return SearchProductCtrl;
            }(Framework.ControllerBase));
            Shared.remeCareSharedModule.controller('searchProductCtrl', function ($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, medicationSearchSvc) { return new SearchProductCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, medicationSearchSvc); });
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
