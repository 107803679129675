var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var MonitoringPartSummary = /** @class */ (function () {
                    function MonitoringPartSummary() {
                        this.parameters = [];
                    }
                    return MonitoringPartSummary;
                }());
                Model.MonitoringPartSummary = MonitoringPartSummary;
                var MonitoringPart = /** @class */ (function () {
                    function MonitoringPart(serverObject) {
                        this.monitoringPartSources = [];
                        if (serverObject != null) {
                            this.id = serverObject.Id;
                            this.name = serverObject.Name;
                            this.sequence = serverObject.Sequence;
                            this.isMergeable = serverObject.IsMergeable;
                            this.width = serverObject.Width;
                            this.type = serverObject.Type;
                            this.defaultPeriod = serverObject.DefaultPeriod;
                            this.assignedGroup = serverObject.AssignedGroup;
                            this.isMobile = serverObject.IsMobile;
                        }
                        else {
                            this.isMergeable = true;
                        }
                    }
                    MonitoringPart.prototype.toServerWrite = function () {
                        var toReturn = {
                            id: this.id,
                            name: this.name,
                            sequence: this.sequence,
                            isMergeable: this.isMergeable,
                            width: this.width,
                            type: this.type,
                            defaultPeriod: this.defaultPeriod,
                            monitoringPartSources: _(this.monitoringPartSources).map(function (mps) { return mps.toServerWrite(); }),
                            assignedGroupId: this.assignedGroup && this.assignedGroup.Id,
                            isMobile: this.isMobile,
                        };
                        return toReturn;
                    };
                    MonitoringPart.prototype.isPeriodConfigEqual = function (part) {
                        return ((this.defaultPeriod == null && part.defaultPeriod == null) ||
                            (this.defaultPeriod &&
                                part.defaultPeriod &&
                                this.defaultPeriod.Quantity === part.defaultPeriod.Quantity &&
                                this.defaultPeriod.Unit === part.defaultPeriod.Unit));
                    };
                    return MonitoringPart;
                }());
                Model.MonitoringPart = MonitoringPart;
                var MonitoringPartTable = /** @class */ (function (_super) {
                    __extends(MonitoringPartTable, _super);
                    function MonitoringPartTable(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.maxRows = serverObject.MaxRows;
                            _this.showTime = serverObject.ShowTime;
                            _this.invert = serverObject.Invert;
                        }
                        else {
                            _this.showTime = true;
                            _this.invert = false;
                        }
                        return _this;
                    }
                    MonitoringPartTable.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.maxRows = this.maxRows;
                        result.showTime = this.showTime;
                        result.invert = this.invert;
                        return result;
                    };
                    return MonitoringPartTable;
                }(MonitoringPart));
                Model.MonitoringPartTable = MonitoringPartTable;
                var MonitoringPartActionTable = /** @class */ (function (_super) {
                    __extends(MonitoringPartActionTable, _super);
                    function MonitoringPartActionTable(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.maxRows = serverObject.MaxRows;
                            _this.showPlannedDates = serverObject.ShowPlannedDates;
                            _this.showEventDates = serverObject.ShowEventDates;
                            _this.showRegistrationDate = serverObject.ShowRegistrationDate;
                            _this.showModificationDate = serverObject.ShowModificationDate;
                            _this.showTime = serverObject.ShowTime;
                        }
                        return _this;
                    }
                    MonitoringPartActionTable.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.maxRows = this.maxRows;
                        result.showPlannedDates = this.showPlannedDates;
                        result.showEventDates = this.showEventDates;
                        result.showRegistrationDate = this.showRegistrationDate;
                        result.showModificationDate = this.showModificationDate;
                        result.showTime = this.showTime;
                        return result;
                    };
                    return MonitoringPartActionTable;
                }(MonitoringPart));
                Model.MonitoringPartActionTable = MonitoringPartActionTable;
                var MonitoringPartActionTimeLine = /** @class */ (function (_super) {
                    __extends(MonitoringPartActionTimeLine, _super);
                    function MonitoringPartActionTimeLine(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.showPlannedDates = serverObject.ShowPlannedDates;
                            _this.showEventDates = serverObject.ShowEventDates;
                            _this.showRegistrationDate = serverObject.ShowRegistrationDate;
                            _this.showModificationDate = serverObject.ShowModificationDate;
                        }
                        return _this;
                    }
                    MonitoringPartActionTimeLine.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.showPlannedDates = this.showPlannedDates;
                        result.showEventDates = this.showEventDates;
                        result.showRegistrationDate = this.showRegistrationDate;
                        result.showModificationDate = this.showModificationDate;
                        return result;
                    };
                    return MonitoringPartActionTimeLine;
                }(MonitoringPart));
                Model.MonitoringPartActionTimeLine = MonitoringPartActionTimeLine;
                var MonitoringPartChart = /** @class */ (function (_super) {
                    __extends(MonitoringPartChart, _super);
                    function MonitoringPartChart(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.ordinateMaxValue = serverObject.OrdinateMaxValue;
                            _this.ordinateMinValue = serverObject.OrdinateMinValue;
                            _this.ordinatePercentageAboveHighest = serverObject.OrdinatePercentageAboveHighest;
                            _this.ordinatePercentageBelowLowest = serverObject.OrdinatePercentageBelowLowest;
                        }
                        return _this;
                    }
                    MonitoringPartChart.prototype.toServerWrite = function () {
                        var result = _super.prototype.toServerWrite.call(this);
                        result.ordinateMaxValue = this.ordinateMaxValue;
                        result.ordinateMinValue = this.ordinateMinValue;
                        result.ordinatePercentageAboveHighest = this.ordinatePercentageAboveHighest;
                        result.ordinatePercentageBelowLowest = this.ordinatePercentageBelowLowest;
                        return result;
                    };
                    MonitoringPartChart.prototype.isAxisConfigEqual = function (chart) {
                        return (this.ordinateMaxValue === chart.ordinateMaxValue &&
                            this.ordinateMinValue === chart.ordinateMinValue &&
                            this.ordinatePercentageAboveHighest === chart.ordinatePercentageAboveHighest &&
                            this.ordinatePercentageBelowLowest === chart.ordinatePercentageBelowLowest);
                    };
                    return MonitoringPartChart;
                }(MonitoringPart));
                Model.MonitoringPartChart = MonitoringPartChart;
                var MonitoringPartTimeLine = /** @class */ (function (_super) {
                    __extends(MonitoringPartTimeLine, _super);
                    function MonitoringPartTimeLine() {
                        return _super !== null && _super.apply(this, arguments) || this;
                    }
                    return MonitoringPartTimeLine;
                }(MonitoringPart));
                Model.MonitoringPartTimeLine = MonitoringPartTimeLine;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
