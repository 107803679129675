namespace RemeCare.Shared.Contract.Code {
    export enum CareRequestPartType {
        Patient = 1,
        CareRequestor = 2,
        CareRequestRole = 3,
        QuantitativeAnamnesis = 4,
        QualitativeAnamnesis = 5,
        TherapyOption = 6,
        Date = 7,
        String = 8,
        RequestDocument = 9,
        Hospitalisation = 10,
        Consultation = 11,
        MedicationSchemaTherapyAction = 12,
        MedicationSchemaOther = 13,
        Comorbidities = 14,
        TreatedDiseases = 15,
        Signature = 16,
    }
}
