namespace RemeCare.Shared.Framework.Model {
    export class TimeSpan {
        public Days: number;
        public Hours: number;
        public Minutes: number;
        public Seconds: number;

        constructor(serverObject: string | null | undefined) {

            //Can be 2.12:02:09 or just 12:02:09
            if (!serverObject) {
                return null;
            }

            const parts = serverObject.split(':');
            if (parts.length !== 3) {
                return null;
            }

            //Parse day and hours e.g. 2.12'
            const dayHours = parts[0].split('.');
            if (dayHours.length === 2) {
                this.Days = parseInt(dayHours[0], 10);
                this.Hours = parseInt(dayHours[1], 10);
            } else if (dayHours.length === 1) {
                this.Hours = parseInt(dayHours[0], 10);
                this.Days = 0;
            } else {
                //Something went wrong
                return null;
            }

            this.Minutes = parseInt(parts[1], 10);
            this.Seconds = parseInt(parts[2], 10);
        }
    }
}
