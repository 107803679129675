namespace RemeCare.Shared.Framework.Model {
    import Guid = Shared.Contract.Guid;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export class MonitoringPartSummary {
        public id: Guid;
        public type: EnumTranslation;
        public width: EnumTranslation;
        public parameters: EntityTranslation[] = [];
        public sequence: number;
        public assignedGroup: EnumTranslation;
        public isMobile: boolean;
    }

    export abstract class MonitoringPart {
        public id: Guid;
        public name: string;
        public sequence: number;
        public isMergeable: boolean;
        public width: Shared.Contract.Code.PartWidth;
        public type: Shared.Contract.Code.MonitoringPartType;
        public defaultPeriod: Shared.Contract.Read.IDuration;
        public monitoringPartSources: MonitoringPartSource[] = [];
        public assignedGroup: Shared.Contract.IEnumTranslation;
        public isMobile: boolean;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPart) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.sequence = serverObject.Sequence;
                this.isMergeable = serverObject.IsMergeable;
                this.width = serverObject.Width;
                this.type = serverObject.Type;
                this.defaultPeriod = serverObject.DefaultPeriod;
                this.assignedGroup = serverObject.AssignedGroup;
                this.isMobile = serverObject.IsMobile;
            } else {
                this.isMergeable = true;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPart {
            const toReturn: Shared.Contract.Write.IMonitoringPart = {
                id: this.id,
                name: this.name,
                sequence: this.sequence,
                isMergeable: this.isMergeable,
                width: this.width,
                type: this.type,
                defaultPeriod: this.defaultPeriod,
                monitoringPartSources: _(this.monitoringPartSources).map((mps) => mps.toServerWrite()),
                assignedGroupId: this.assignedGroup && this.assignedGroup.Id,
                isMobile: this.isMobile,
            };

            return toReturn;
        }

        public isPeriodConfigEqual(part: MonitoringPart): boolean {
            return (
                (this.defaultPeriod == null && part.defaultPeriod == null) ||
                (this.defaultPeriod &&
                    part.defaultPeriod &&
                    this.defaultPeriod.Quantity === part.defaultPeriod.Quantity &&
                    this.defaultPeriod.Unit === part.defaultPeriod.Unit)
            );
        }
    }

    export class MonitoringPartTable extends MonitoringPart {
        public maxRows: number;
        public showTime: boolean;
        public invert: boolean;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartTable) {
            super(serverObject);
            if (serverObject != null) {
                this.maxRows = serverObject.MaxRows;
                this.showTime = serverObject.ShowTime;
                this.invert = serverObject.Invert;
            } else {
                this.showTime = true;
                this.invert = false;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartTable {
            const result = super.toServerWrite() as Shared.Contract.Write.IMonitoringPartTable;
            result.maxRows = this.maxRows;
            result.showTime = this.showTime;
            result.invert = this.invert;
            return result;
        }
    }

    export class MonitoringPartActionTable extends MonitoringPart {
        public maxRows: number;
        public showPlannedDates: boolean;
        public showEventDates: boolean;
        public showRegistrationDate: boolean;
        public showModificationDate: boolean;
        public showTime: boolean;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartActionTable) {
            super(serverObject);
            if (serverObject != null) {
                this.maxRows = serverObject.MaxRows;
                this.showPlannedDates = serverObject.ShowPlannedDates;
                this.showEventDates = serverObject.ShowEventDates;
                this.showRegistrationDate = serverObject.ShowRegistrationDate;
                this.showModificationDate = serverObject.ShowModificationDate;
                this.showTime = serverObject.ShowTime;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartActionTable {
            const result = super.toServerWrite() as Shared.Contract.Write.IMonitoringPartActionTable;
            result.maxRows = this.maxRows;
            result.showPlannedDates = this.showPlannedDates;
            result.showEventDates = this.showEventDates;
            result.showRegistrationDate = this.showRegistrationDate;
            result.showModificationDate = this.showModificationDate;
            result.showTime = this.showTime;
            return result;
        }
    }

    export class MonitoringPartActionTimeLine extends MonitoringPart {
        public showPlannedDates: boolean;
        public showEventDates: boolean;
        public showRegistrationDate: boolean;
        public showModificationDate: boolean;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartActionTimeLine) {
            super(serverObject);
            if (serverObject != null) {
                this.showPlannedDates = serverObject.ShowPlannedDates;
                this.showEventDates = serverObject.ShowEventDates;
                this.showRegistrationDate = serverObject.ShowRegistrationDate;
                this.showModificationDate = serverObject.ShowModificationDate;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartActionTimeLine {
            const result = super.toServerWrite() as Shared.Contract.Write.IMonitoringPartActionTimeLine;
            result.showPlannedDates = this.showPlannedDates;
            result.showEventDates = this.showEventDates;
            result.showRegistrationDate = this.showRegistrationDate;
            result.showModificationDate = this.showModificationDate;
            return result;
        }
    }

    export class MonitoringPartChart extends MonitoringPart {
        public ordinateMaxValue: number;
        public ordinateMinValue: number;
        public ordinatePercentageAboveHighest: number;
        public ordinatePercentageBelowLowest: number;

        constructor(serverObject?: Shared.Contract.Read.IMonitoringPartChart) {
            super(serverObject);
            if (serverObject != null) {
                this.ordinateMaxValue = serverObject.OrdinateMaxValue;
                this.ordinateMinValue = serverObject.OrdinateMinValue;
                this.ordinatePercentageAboveHighest = serverObject.OrdinatePercentageAboveHighest;
                this.ordinatePercentageBelowLowest = serverObject.OrdinatePercentageBelowLowest;
            }
        }

        public toServerWrite(): Shared.Contract.Write.IMonitoringPartChart {
            const result = super.toServerWrite() as Shared.Contract.Write.IMonitoringPartChart;
            result.ordinateMaxValue = this.ordinateMaxValue;
            result.ordinateMinValue = this.ordinateMinValue;
            result.ordinatePercentageAboveHighest = this.ordinatePercentageAboveHighest;
            result.ordinatePercentageBelowLowest = this.ordinatePercentageBelowLowest;
            return result;
        }

        public isAxisConfigEqual(chart: MonitoringPartChart): boolean {
            return (
                this.ordinateMaxValue === chart.ordinateMaxValue &&
                this.ordinateMinValue === chart.ordinateMinValue &&
                this.ordinatePercentageAboveHighest === chart.ordinatePercentageAboveHighest &&
                this.ordinatePercentageBelowLowest === chart.ordinatePercentageBelowLowest
            );
        }
    }

    export class MonitoringPartTimeLine extends MonitoringPart {}
}
