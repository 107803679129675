namespace RemeCare.Shared.Service {
    import ISearchResult = Shared.Contract.ISearchResult;
    import IMedication = Contract.Read.IMedication;
    import SearchMedicationQuery = Contract.Read.Query.ISearchMedicationQuery;

    export class MedicationSearchSvc extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public searchMedicationsAsync(query: SearchMedicationQuery): Promise<ISearchResult<IMedication>> {
            const url = 'Medication';
            return super.getPromise(url, query, 'medications');
        }

        public getMedicationsAsync(productIds: Shared.Contract.Guid[]): Promise<IMedication[]> {
            const url = 'Medication';
            const query: SearchMedicationQuery = {
                pageSize: productIds.length,
                productIds: productIds,
            };
            return this.getPromise(url, query, null, null, (r) => r.Items);
        }

        public getMedicationAsync(productId: Shared.Contract.Guid): Promise<IMedication> {
            const url = `Medication/${productId}`;
            return this.getPromise(url);
        }
    }

    remeCareSharedModule.service('medicationSearchSvc', MedicationSearchSvc);
}
