namespace RemeCare.Shared.Framework.Model {
    export class CodeSystem {
        public id: string;
        public name: string;
        public uri: string;

        constructor(serverObject?: Contract.Read.ICodeSystem) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.uri = serverObject.Uri;
            }
        }
    }
}
