var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Grid;
            (function (Grid) {
                var PrevNextPagedGrid = /** @class */ (function (_super) {
                    __extends(PrevNextPagedGrid, _super);
                    function PrevNextPagedGrid(scope, searchFunction, gridOptions, $timeout, uiGridConstants, $window, loadMeasuringSvc, urlBinderFactory, propertyMappers, searchCriteriaSetter) {
                        var _this = _super.call(this, scope, searchFunction, gridOptions, $timeout, uiGridConstants, $window, loadMeasuringSvc, urlBinderFactory, propertyMappers, searchCriteriaSetter) || this;
                        _this.pagingType = Grid.PagingType.PrevNext;
                        _this.maxPageReached = 1;
                        return _this;
                    }
                    PrevNextPagedGrid.prototype.currentPageChanged = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.hasPreviousPage = this.pagingOptions.currentPage > 1;
                                        this.hasNextPage = this.pagingOptions.currentPage < this.maxPageReached;
                                        this.maxPageReached = Math.max(this.pagingOptions.currentPage, this.maxPageReached);
                                        return [4 /*yield*/, this.executeSearch(true)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PrevNextPagedGrid.prototype.isPaged = function () {
                        return this.hasNextPage || this.hasPreviousPage;
                    };
                    PrevNextPagedGrid.prototype.executeSearch = function (isPagingSearch) {
                        return __awaiter(this, void 0, void 0, function () {
                            var searchResult;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!isPagingSearch) {
                                            this.maxPageReached = 1;
                                            this.hasNextPage = false;
                                            this.totalItems = null;
                                        }
                                        return [4 /*yield*/, this.searchFunction(this.pagingOptions.currentPage, this.pagingOptions.pageSize, this.sortOptions.field, this.sortOptions.direction, this.searchCriteria, false, true)];
                                    case 1:
                                        searchResult = _a.sent();
                                        this.setData(searchResult.Items);
                                        this.hasNextPage = searchResult.HasNextPage;
                                        this.maxPageReached = Math.max(this.maxPageReached, this.pagingOptions.currentPage);
                                        this.isSearchFiltered();
                                        if (!isPagingSearch) {
                                            this.searchFunction(1, 0, undefined, undefined, this.searchCriteria, true, false).then(function (searchResult) {
                                                _this.totalItems = searchResult.Total;
                                            });
                                        }
                                        return [2 /*return*/, searchResult];
                                }
                            });
                        });
                    };
                    return PrevNextPagedGrid;
                }(Grid.Grid));
                Grid.PrevNextPagedGrid = PrevNextPagedGrid;
            })(Grid = Framework.Grid || (Framework.Grid = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
