var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var CareRequestUIControlType;
                (function (CareRequestUIControlType) {
                    CareRequestUIControlType[CareRequestUIControlType["PatientName"] = 1] = "PatientName";
                    CareRequestUIControlType[CareRequestUIControlType["PatientNationalNumber"] = 2] = "PatientNationalNumber";
                    CareRequestUIControlType[CareRequestUIControlType["PatientBirthDate"] = 3] = "PatientBirthDate";
                    CareRequestUIControlType[CareRequestUIControlType["PatientCommunicationLanguage"] = 4] = "PatientCommunicationLanguage";
                    CareRequestUIControlType[CareRequestUIControlType["PatientGender"] = 5] = "PatientGender";
                    CareRequestUIControlType[CareRequestUIControlType["PatientAddress"] = 6] = "PatientAddress";
                    CareRequestUIControlType[CareRequestUIControlType["PatientCountry"] = 7] = "PatientCountry";
                    CareRequestUIControlType[CareRequestUIControlType["PatientNationality"] = 8] = "PatientNationality";
                    CareRequestUIControlType[CareRequestUIControlType["PatientTelephone"] = 9] = "PatientTelephone";
                    CareRequestUIControlType[CareRequestUIControlType["PatientEmail"] = 10] = "PatientEmail";
                    CareRequestUIControlType[CareRequestUIControlType["PatientMaritalStatus"] = 11] = "PatientMaritalStatus";
                    CareRequestUIControlType[CareRequestUIControlType["PatientTechnologicalAffinity"] = 12] = "PatientTechnologicalAffinity";
                    CareRequestUIControlType[CareRequestUIControlType["PatientEducationLevel"] = 13] = "PatientEducationLevel";
                    CareRequestUIControlType[CareRequestUIControlType["PatientCodeStudy"] = 14] = "PatientCodeStudy";
                    CareRequestUIControlType[CareRequestUIControlType["Contact"] = 15] = "Contact";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonName"] = 16] = "ContactPersonName";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonGender"] = 17] = "ContactPersonGender";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonTypeSupport"] = 18] = "ContactPersonTypeSupport";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonAddress"] = 19] = "ContactPersonAddress";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonCountry"] = 20] = "ContactPersonCountry";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonTelephoneMobile"] = 21] = "ContactPersonTelephoneMobile";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonEmail"] = 22] = "ContactPersonEmail";
                    CareRequestUIControlType[CareRequestUIControlType["ContactPersonNonProfHCProvider"] = 23] = "ContactPersonNonProfHCProvider";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorHCProfessional"] = 24] = "CareRequestorHCProfessional";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorName"] = 25] = "CareRequestorName";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorTelephoneMobile"] = 26] = "CareRequestorTelephoneMobile";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorRIZIV"] = 27] = "CareRequestorRIZIV";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorEmail"] = 28] = "CareRequestorEmail";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorContactPerson"] = 29] = "CareRequestorContactPerson";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorContactName"] = 30] = "CareRequestorContactName";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorContactTelephoneMobile"] = 31] = "CareRequestorContactTelephoneMobile";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorContactFunction"] = 32] = "CareRequestorContactFunction";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorSpeciality"] = 33] = "CareRequestorSpeciality";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorHCOrganisation"] = 34] = "CareRequestorHCOrganisation";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorIntermediary"] = 35] = "CareRequestorIntermediary";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestRole"] = 36] = "CareRequestRole";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestRoleName"] = 37] = "CareRequestRoleName";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestRoleTelephoneMobile"] = 38] = "CareRequestRoleTelephoneMobile";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestRoleRIZIV"] = 39] = "CareRequestRoleRIZIV";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestRoleEmail"] = 40] = "CareRequestRoleEmail";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestRoleAddress"] = 41] = "CareRequestRoleAddress";
                    CareRequestUIControlType[CareRequestUIControlType["QuantitativeAnamnesis"] = 42] = "QuantitativeAnamnesis";
                    CareRequestUIControlType[CareRequestUIControlType["String"] = 43] = "String";
                    CareRequestUIControlType[CareRequestUIControlType["TherapyOption"] = 44] = "TherapyOption";
                    CareRequestUIControlType[CareRequestUIControlType["Date"] = 45] = "Date";
                    CareRequestUIControlType[CareRequestUIControlType["RequestDocument"] = 46] = "RequestDocument";
                    CareRequestUIControlType[CareRequestUIControlType["Comorbidities"] = 47] = "Comorbidities";
                    CareRequestUIControlType[CareRequestUIControlType["TreatedDiseases"] = 48] = "TreatedDiseases";
                    CareRequestUIControlType[CareRequestUIControlType["Hospitalisation"] = 49] = "Hospitalisation";
                    CareRequestUIControlType[CareRequestUIControlType["HospitalisationStartDate"] = 50] = "HospitalisationStartDate";
                    CareRequestUIControlType[CareRequestUIControlType["HospitalisationEndDate"] = 51] = "HospitalisationEndDate";
                    CareRequestUIControlType[CareRequestUIControlType["HospitalisationDepartment"] = 52] = "HospitalisationDepartment";
                    CareRequestUIControlType[CareRequestUIControlType["HospitalisationReason"] = 53] = "HospitalisationReason";
                    CareRequestUIControlType[CareRequestUIControlType["HospitalisationCarePlanRelated"] = 54] = "HospitalisationCarePlanRelated";
                    CareRequestUIControlType[CareRequestUIControlType["Consultation"] = 55] = "Consultation";
                    CareRequestUIControlType[CareRequestUIControlType["ConsultationStartDate"] = 56] = "ConsultationStartDate";
                    CareRequestUIControlType[CareRequestUIControlType["ConsultationEndDate"] = 57] = "ConsultationEndDate";
                    CareRequestUIControlType[CareRequestUIControlType["ConsultationCareProviderType"] = 58] = "ConsultationCareProviderType";
                    CareRequestUIControlType[CareRequestUIControlType["ConsultationReason"] = 59] = "ConsultationReason";
                    CareRequestUIControlType[CareRequestUIControlType["ConsultationCarePlanRelated"] = 60] = "ConsultationCarePlanRelated";
                    CareRequestUIControlType[CareRequestUIControlType["MedicationSchema"] = 61] = "MedicationSchema";
                    CareRequestUIControlType[CareRequestUIControlType["QualitativeAnamnesis"] = 62] = "QualitativeAnamnesis";
                    CareRequestUIControlType[CareRequestUIControlType["MedicationSchemaTherapyAction"] = 63] = "MedicationSchemaTherapyAction";
                    CareRequestUIControlType[CareRequestUIControlType["CareRequestorContactEmail"] = 64] = "CareRequestorContactEmail";
                    CareRequestUIControlType[CareRequestUIControlType["PatientMobile"] = 65] = "PatientMobile";
                    CareRequestUIControlType[CareRequestUIControlType["Signature"] = 66] = "Signature";
                })(CareRequestUIControlType = Code.CareRequestUIControlType || (Code.CareRequestUIControlType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
