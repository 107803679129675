var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Service;
        (function (Service) {
            var MedicationSearchSvc = /** @class */ (function (_super) {
                __extends(MedicationSearchSvc, _super);
                function MedicationSearchSvc() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                MedicationSearchSvc.prototype.searchMedicationsAsync = function (query) {
                    var url = 'Medication';
                    return _super.prototype.getPromise.call(this, url, query, 'medications');
                };
                MedicationSearchSvc.prototype.getMedicationsAsync = function (productIds) {
                    var url = 'Medication';
                    var query = {
                        pageSize: productIds.length,
                        productIds: productIds,
                    };
                    return this.getPromise(url, query, null, null, function (r) { return r.Items; });
                };
                MedicationSearchSvc.prototype.getMedicationAsync = function (productId) {
                    var url = "Medication/" + productId;
                    return this.getPromise(url);
                };
                MedicationSearchSvc.$inject = Shared.Framework.ApiServiceBase.injectionParameters;
                return MedicationSearchSvc;
            }(Shared.Framework.ApiServiceBase));
            Service.MedicationSearchSvc = MedicationSearchSvc;
            Shared.remeCareSharedModule.service('medicationSearchSvc', MedicationSearchSvc);
        })(Service = Shared.Service || (Shared.Service = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
