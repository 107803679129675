var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Directive;
            (function (Directive) {
                var InputSelectBase = /** @class */ (function () {
                    function InputSelectBase($templateCache, $compile, $timeout, idService) {
                        var _this = this;
                        this.$templateCache = $templateCache;
                        this.$compile = $compile;
                        this.$timeout = $timeout;
                        this.idService = idService;
                        this.restrict = 'E';
                        this.require = '^form';
                        this.scope = {
                            required: '=ngRequired',
                            model: '=ngModel',
                            disabled: '=ngDisabled',
                            readOnly: '=ngReadonly',
                            change: '&?ngChange',
                            hasWarning: '<?ngWarning',
                            blur: '&?ngBlur',
                            adjustSize: '=',
                            options: '=',
                            info: '@',
                            displayText: '@',
                            tracker: '@',
                            value: '@',
                            label: '@',
                            labelEdit: '&?',
                            spinner: '@',
                            inputClass: '<',
                            filter: '@',
                            searchFunction: '&?',
                            searchFunctionDisabled: '=',
                            guidanceIconLocation: '<',
                            guidanceIconFunc: '<',
                            uniqueId: '@',
                        };
                        this.link = function (scope, element, attrs, formCtrl) {
                            scope.checkDisabledLabels = function () {
                                if (!scope.disabled) {
                                    return;
                                }
                                _this.checkDisabledLabels(element);
                            };
                            scope.uniqueName = _this.idService.generateId();
                            scope.guidanceIconLocations = RemeCare.Shared.Contract.Code.GuidanceIconLocation;
                            var optionsString = 'option';
                            if (scope.value) {
                                optionsString += "." + scope.value;
                            }
                            if (scope.displayText) {
                                optionsString += " as option." + scope.displayText;
                            }
                            optionsString += ' for option in options';
                            if (scope.filter) {
                                optionsString += ' | filter: ' + scope.filter;
                            }
                            if (scope.tracker) {
                                optionsString += " track by option." + scope.tracker;
                            }
                            var template = _this.$templateCache.get(_this.template());
                            template = template.replace('$$options$$', optionsString);
                            element.html(template).show();
                            _this.$compile(element.contents())(scope);
                            scope.formCtrl = formCtrl;
                            scope.jsonModel = {
                                model: scope.model,
                            };
                            scope.$watch(function (s) { return s.model; }, function (newValue, oldValue) {
                                if (newValue !== oldValue && newValue !== scope.jsonModel.model) {
                                    scope.jsonModel.model = newValue;
                                }
                            });
                            scope.$watch(function (s) { return s.jsonModel.model; }, function (newValue, oldValue) {
                                if (newValue !== oldValue && newValue !== scope.model) {
                                    scope.model = newValue;
                                }
                            });
                            scope.selectionChanged = function () {
                                _this.$timeout(function () {
                                    scope.change && scope.change();
                                });
                            };
                        };
                    }
                    InputSelectBase.prototype.checkDisabledLabels = function (element) {
                        var result = element.find('li');
                        if (result.length > 0) {
                            result.addClass('disabled');
                            for (var i = 0; i < result.length; i++) {
                                // Replace existing <a> element with new to remove all event/click handlers
                                result[i].innerHTML = '<a>' + result[i].children[0].innerHTML + '</a>';
                            }
                        }
                    };
                    return InputSelectBase;
                }());
                var InputSelectDirective = /** @class */ (function (_super) {
                    __extends(InputSelectDirective, _super);
                    function InputSelectDirective() {
                        return _super !== null && _super.apply(this, arguments) || this;
                    }
                    InputSelectDirective.prototype.template = function () {
                        return 'views/formElements/inputSelect.html';
                    };
                    return InputSelectDirective;
                }(InputSelectBase));
                Shared.remeCareSharedModule.directive('rcInputSelect', function ($templateCache, $compile, $timeout, idService) {
                    return new InputSelectDirective($templateCache, $compile, $timeout, idService);
                });
                var InputMultiselectDirective = /** @class */ (function (_super) {
                    __extends(InputMultiselectDirective, _super);
                    function InputMultiselectDirective() {
                        return _super !== null && _super.apply(this, arguments) || this;
                    }
                    InputMultiselectDirective.prototype.template = function () {
                        return 'views/formElements/inputMultiselect.html';
                    };
                    return InputMultiselectDirective;
                }(InputSelectBase));
                Shared.remeCareSharedModule.directive('rcInputMultiselect', function ($templateCache, $compile, $timeout, idService) {
                    return new InputMultiselectDirective($templateCache, $compile, $timeout, idService);
                });
            })(Directive = Framework.Directive || (Framework.Directive = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
