var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Contract;
        (function (Contract) {
            var Code;
            (function (Code) {
                var ProcessParameterType;
                (function (ProcessParameterType) {
                    ProcessParameterType[ProcessParameterType["Anamnesis"] = 1] = "Anamnesis";
                    ProcessParameterType[ProcessParameterType["TherapyOption"] = 2] = "TherapyOption";
                    ProcessParameterType[ProcessParameterType["Contact"] = 3] = "Contact";
                    ProcessParameterType[ProcessParameterType["Objective"] = 4] = "Objective";
                    ProcessParameterType[ProcessParameterType["RuleThreshold"] = 5] = "RuleThreshold";
                    ProcessParameterType[ProcessParameterType["StructuralTherapyAction"] = 6] = "StructuralTherapyAction";
                    ProcessParameterType[ProcessParameterType["CareRequest"] = 7] = "CareRequest";
                    ProcessParameterType[ProcessParameterType["CodeSetType"] = 8] = "CodeSetType";
                    ProcessParameterType[ProcessParameterType["CodeSetItem"] = 9] = "CodeSetItem";
                    ProcessParameterType[ProcessParameterType["Characteristic"] = 10] = "Characteristic";
                    ProcessParameterType[ProcessParameterType["ObservableEntity"] = 11] = "ObservableEntity";
                    ProcessParameterType[ProcessParameterType["TherapyAction"] = 12] = "TherapyAction";
                    ProcessParameterType[ProcessParameterType["Therapy"] = 13] = "Therapy";
                    ProcessParameterType[ProcessParameterType["CarePlan"] = 14] = "CarePlan";
                })(ProcessParameterType = Code.ProcessParameterType || (Code.ProcessParameterType = {}));
            })(Code = Contract.Code || (Contract.Code = {}));
        })(Contract = Shared.Contract || (Shared.Contract = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
