namespace RemeCare.Shared.Framework.Model {
    export class PossibleProductForAction {
        public ProductId: Contract.Guid;
        public Product: Contract.IEntityTranslation;
        public ProductClassId: Contract.Guid;
        public ProductClass: Contract.IEntityTranslation;
        public ProductDisplayClasses: Contract.IEntityTranslation[];
        public IsDefault: boolean;
        public IsPreferred: boolean;
        public IsActive: boolean;

        public displayClasses(): Contract.IEntityTranslation[] {
            if (this.ProductClass && this.ProductClass.Id) {
                return [this.ProductClass];
            }
            return this.ProductDisplayClasses;
        }
    }
}
