namespace RemeCare.Shared.Contract.Write {
    export interface ILocationSetting {
        LocationSettingId: Guid;
        Sequence: number;
        UsesHCOrgAddress: boolean;
        Duration: Contract.IDuration;
        AddressTypePatientId: Guid;
    }

    export interface IRequestInfoFileType {
        IsActive: boolean;
        FileTypeId: Guid;
    }

    export interface IAnswerCondition {
        AnswerCodeSetItemId: Shared.Contract.Guid;
        GoToActionPartId: Shared.Contract.Guid;
    }

    export interface IInformationObjectParameter {
        InformationObjectId: Guid;
        Name: string;
        Description: string;
        Url: string;
        TypeId: Guid;
        Copy: boolean;
    }

    export interface IRelatedStructuralTherapyActionParameter {
        ChildStructuralTherapyActionId: Guid;
        TherapyActionRelationTypeId: Shared.Contract.Read.TherapyActionRelationType;
        Delay: Shared.Contract.IDuration;
    }

    export interface IPossibleActionDurationOption {
        Id?: Contract.Guid;
        DurationOfAction?: string;
        Sequence: number;
        Name: string;
    }

    export interface ITherapyActionPartContextSettingParameter {
        TherapyActionPartContextSettingId?: Guid;
        IsMandatory: boolean;
        GuidanceCode: string;
        GuidanceEnabled: boolean;
        IsLeftOutForRemeCoach: boolean;
        SyncToFhir: boolean;
        CodeSystemOverrides: ICodeSystemOverride[];
        RemeCoachDefaultValue?: number;
        TherapyActionPartId: Guid;
        HasLocation: boolean;
        HasExecutor: boolean;
        CodeSetTypeId: Guid;
        DateTypeId: Contract.Code.DateTypeCode;
        HasTime: boolean;
        IsWindow: boolean;
        Name: string;
        NumberOfAttempts: number;
        IsTherapyMediaLibItem: boolean;
        SimpleInformationObject: IInformationObjectParameter;
        AnswerConditions: IAnswerCondition[];
        ShowObservableEntity: boolean;
        ShowCharacteristic: boolean;
        ExternalContextReference: string;
        ExternalParameterReference: string;
        Decimals: number;
        InputId: number;
        IsTelemonitoringEnabled: boolean;
        IntendedValueId: Shared.Contract.Code.IntendedValue;
        HasStandardPhrases: boolean;
        AllowsAllFileTypes: boolean;
        HasReferenceDate: boolean;
        IsFollowUpDocument: boolean;
        IsExternalDocument: boolean;
        RequestInfoFileTypes: IRequestInfoFileType[];
        DefaultDelayDuration: Contract.IDuration;
        DefaultFromTime: string;
        DefaultUntilTime: string;
        IsBasedOnCarePlanStartDate: boolean;
        LocationSettings: ILocationSetting[];
        IsEditable: boolean;
        CanPersonaliseTelemonitoringDeactivation: boolean;
        CanPersonaliseToHidePart: boolean;
        ChildRelatedStructuralTherapyAction: IRelatedStructuralTherapyActionParameter;
        DocumentCategories: Guid[];
        IsCrossProcessInstance: boolean;
        LinkedThresholdId: Guid;
        MinimumValue?: number;
        MaximumValue?: number;
        StructuralTherapyActionToPlanId?: Guid;
        IsMandatoryOverrides: ITherapyActionPartContextSettingMandatoryOverride[];
        ExternalReference: string;
        TreatRegistrationAsRemark: boolean;
    }

    export class ITherapyActionPartContextSettingMandatoryOverride {
        public MandatoryTherapyActionPartId: Guid;
        public CareActCodeSetItemId: Guid;
    }
}
