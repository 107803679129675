var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Model;
            (function (Model) {
                var IntraDayTiming = /** @class */ (function () {
                    function IntraDayTiming(serverObject) {
                        this.targetValues = [];
                        if (serverObject != null) {
                            this.intraDayTimingId = serverObject.Id;
                            this.preferredTime = Shared.DateHelper.serverTimeToDate(serverObject.PreferredTime);
                            this.preferredTimeWindowBefore = Shared.DateHelper.serverTimeToTimeSpan(serverObject.PreferredTimeWindowBefore);
                            this.preferredTimeWindowAfter = Shared.DateHelper.serverTimeToTimeSpan(serverObject.PreferredTimeWindowAfter);
                            this.reminderElapseTimeAfterOpeningWindow = Shared.DateHelper.serverTimeToTimeSpan(serverObject.ReminderElapseTimeAfterOpeningWindow);
                        }
                    }
                    IntraDayTiming.prototype.toWriteModel = function () {
                        return {
                            Type: 0,
                            Id: this.intraDayTimingId,
                            PreferredTime: Shared.DateHelper.toServerTimeString(this.preferredTime),
                            PreferredTimeWindowBefore: Shared.DateHelper.timeSpanToServerTimeString(this.preferredTimeWindowBefore),
                            PreferredTimeWindowAfter: Shared.DateHelper.timeSpanToServerTimeString(this.preferredTimeWindowAfter),
                            ReminderElapseTimeAfterOpeningWindow: Shared.DateHelper.timeSpanToServerTimeString(this.reminderElapseTimeAfterOpeningWindow),
                            TargetValues: _(this.targetValues).map(function (tv) { return tv.toWriteModel(); })
                        };
                    };
                    IntraDayTiming.prototype.copy = function () {
                        var result = new IntraDayTiming();
                        this.setCommonParts(result);
                        return result;
                    };
                    IntraDayTiming.prototype.setCommonParts = function (intraDayTiming) {
                        angular.copy(this, intraDayTiming);
                        intraDayTiming.targetValues = _(this.targetValues).map(function (t) { return t.copy(); });
                    };
                    return IntraDayTiming;
                }());
                Model.IntraDayTiming = IntraDayTiming;
                var IntraDayTimingAdministration = /** @class */ (function (_super) {
                    __extends(IntraDayTimingAdministration, _super);
                    function IntraDayTimingAdministration(serverObject) {
                        var _this = _super.call(this, serverObject) || this;
                        if (serverObject != null) {
                            _this.noEatingWindowAfter = Shared.DateHelper.serverTimeToDate(serverObject.NoEatingWindowAfter);
                            _this.noEatingWindowBefore = Shared.DateHelper.serverTimeToDate(serverObject.NoEatingWindowBefore);
                            _this.administrationTiming = serverObject.AdministrationTiming;
                        }
                        return _this;
                    }
                    IntraDayTimingAdministration.prototype.toWriteModel = function () {
                        var result = _super.prototype.toWriteModel.call(this);
                        result.Type = 1;
                        result.NoEatingWindowBefore = Shared.DateHelper.toServerTimeString(this.noEatingWindowBefore);
                        result.NoEatingWindowAfter = Shared.DateHelper.toServerTimeString(this.noEatingWindowAfter);
                        result.AdministrationTiming = this.administrationTiming ? this.administrationTiming.Id : null;
                        return result;
                    };
                    IntraDayTimingAdministration.prototype.copy = function () {
                        var result = new IntraDayTimingAdministration();
                        _super.prototype.setCommonParts.call(this, result);
                        result.noEatingWindowAfter = this.noEatingWindowAfter;
                        result.noEatingWindowBefore = this.noEatingWindowBefore;
                        result.administrationTiming = this.administrationTiming;
                        return result;
                    };
                    return IntraDayTimingAdministration;
                }(IntraDayTiming));
                Model.IntraDayTimingAdministration = IntraDayTimingAdministration;
            })(Model = Framework.Model || (Framework.Model = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
