namespace RemeCare.Shared.Framework.Directive {
    class InputEmailController implements ng.IComponentController {
        // Same as in backend: EmailPropertyValidator
        // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
        public emailRegexPattern = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        public model: string;
        public formCtrl: ng.IFormController;
        public uniqueId: string;

        /** Model used for component in html and to validate */
        public internalModel: string;
        /** Model used to detect changes to this.model */
        private oldModelInput: string;

        constructor(private readonly idService: Shared.Framework.Service.IIdService) {}

        public $onInit(): void {
            this.uniqueId = this.idService.generateId();
            this.internalModel = this.model;
            this.oldModelInput = this.model;
        }

        public $doCheck(): void {
            // Two-way bindings do not support $onChanges()
            // $watch cannot be used inside components
            // Only option left is $doCheck
            if (this.oldModelInput !== this.model) {
                this.internalModel = this.model;
                this.oldModelInput = this.model;
            }
        }

        public onBlur(): void {
            // When validation fails, the model remains empty. This is how angularjs works.
            // Therefore we use an internalModel for the input component and
            //  copy the $viewValue of the form into the real model
            // This is needed for when validation should not occur, eg during temporary save
            this.model = this.formCtrl[this.uniqueId].$viewValue;
        }
    }

    remeCareSharedModule.component('rcInputEmail', {
        bindings: {
            model: '=ngModel',
            required: '=ngRequired',
            disabled: '=ngDisabled',
            readOnly: '=ngReadonly',
            label: '@',
        },
        controller: InputEmailController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/directives/formElements/inputEmail/inputEmail.html',
    });
}
